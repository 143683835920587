import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getProfileBackgrounds,
  ProfileBackground,
} from "../../data/profile/get-backgrounds";
import { updateUser } from "../../data/settings/update-user";
import { formatTime } from "../../lib/date-utils";
import { getFallbackName } from "../../lib/name-utils";
import { useAuthStore } from "../../stores/auth.store";
import Typography from "../typography";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { GradientPicker } from "../ui/gradient-picker";

type UserProfileHeaderProps = {
  backgroundStyle: string;
  avatarUrl: string;
  firstName: string;
  lastName: string;
  level: number;
  experience: number;
  teamName?: string;
  teamCodeName?: string;

  // From user stats
  activeSinceDays?: number;
  totalTime?: number;
  totalActivities?: number;
};

export function UserProfileHeader(props: UserProfileHeaderProps) {
  const params = useParams();
  const loggedInUserId = useAuthStore((store) => store.userId);

  const userId = params?.userId || loggedInUserId;
  const isOwnProfile = userId === loggedInUserId;

  const [backgroundChoices, setBackgroundChoices] = useState<
    Array<ProfileBackground>
  >([]);
  const [backgroundId, setBackgroundId] = useState<string>("");
  const [background, setBackground] = useState(
    props.backgroundStyle || "linear-gradient(to bottom right,#accbee,#e7f0fd)"
  );

  useEffect(() => {
    const updateUserBackground = async () => {
      if (isOwnProfile && backgroundId !== "") {
        await updateUser({
          userId,
          backgroundId,
        });
      }
    };

    updateUserBackground();
  }, [isOwnProfile, userId, backgroundId]);

  useEffect(() => {
    setBackground(
      props.backgroundStyle ||
        "linear-gradient(to bottom right,#accbee,#e7f0fd)"
    );
  }, [props.backgroundStyle]);

  useEffect(() => {
    const loadBackgrounds = async () => {
      const data = await getProfileBackgrounds();

      setBackgroundChoices(data);
    };

    loadBackgrounds();
  }, []);

  return (
    <div className="flex flex-col w-full">
      <div
        className="flex flex-row items-center gap-4 rounded-lg w-full !bg-cover transition-all relative p-4 md:h-30 md:items-end"
        style={{ background }}
      >
        <Avatar size="large" className="shrink-0">
          <AvatarImage src={props.avatarUrl} />
          <AvatarFallback>
            {getFallbackName(props.firstName, props.lastName)}
          </AvatarFallback>
        </Avatar>

        <div className="flex flex-col md:flex-row md:items-center md:gap-7 md:flex-grow md:bg-gray-800 md:rounded-lg md:bg-clip-padding md:backdrop-filter md:backdrop-blur-md md:bg-opacity-30 md:p-4">
          <div className="md:pr-4">
            <Typography className="text-white md:text-xl" variant="h4">
              {props.firstName} {props.lastName}
            </Typography>

            <Typography className="text-white text-sm" variant="p">
              Level {props.level}{" "}
              {props.teamCodeName && `• ${props.teamCodeName}`}
            </Typography>
          </div>

          {!!props.totalActivities && (
            <div className="hidden md:block">
              <Typography className="text-white" variant="p" affects="light">
                Activities
              </Typography>

              <Typography variant="p" className="text-white font-bold">
                {props.totalActivities}
              </Typography>
            </div>
          )}

          {!!props.totalTime && (
            <div className="hidden md:block">
              <Typography className="text-white" variant="p" affects="light">
                Total time
              </Typography>

              <Typography variant="p" className="text-white font-bold">
                {formatTime(props.totalTime, "HH[h] mm[m]")}
              </Typography>
            </div>
          )}

          {!!props.activeSinceDays && (
            <div className="hidden md:block">
              <Typography className="text-white" variant="p" affects="light">
                Active since
              </Typography>

              <Typography variant="p" className="text-white font-bold">
                {props.activeSinceDays} days
              </Typography>
            </div>
          )}

          {!!props.experience && (
            <div className="hidden md:block">
              <Typography className="text-white" variant="p" affects="light">
                Experience
              </Typography>

              <Typography variant="p" className="text-white font-bold">
                {props.experience.toLocaleString()}
              </Typography>
            </div>
          )}
        </div>

        {isOwnProfile && (
          <div className="absolute bottom-2 right-2">
            <GradientPicker
              background={background}
              setBackground={setBackground}
              setBackgroundId={setBackgroundId}
              backgroundChoices={backgroundChoices}
            />
          </div>
        )}
      </div>

      {!!props.totalActivities &&
        !!props.activeSinceDays &&
        !!props.totalTime && (
          <div className="flex flex-wrap gap-4 mt-4 p-4 bg-gray-100 rounded-lg md:hidden">
            <div className="w-[calc(50%-0.5rem)]">
              <Typography variant="p" affects="light">
                Activities
              </Typography>
              <Typography variant="p" className="font-bold">
                {props.totalActivities}
              </Typography>
            </div>

            <div className="w-[calc(50%-0.5rem)]">
              <Typography variant="p" affects="light">
                Total time
              </Typography>
              <Typography variant="p" className="font-bold">
                {formatTime(props.totalTime, "HH[h] mm[m]")}
              </Typography>
            </div>

            <div className="w-[calc(50%-0.5rem)]">
              <Typography variant="p" affects="light">
                Active since
              </Typography>
              <Typography variant="p" className="font-bold">
                {props.activeSinceDays} days
              </Typography>
            </div>

            <div className="w-[calc(50%-0.5rem)]">
              <Typography variant="p" affects="light">
                Experience
              </Typography>
              <Typography variant="p" className="font-bold">
                {props.experience.toLocaleString()}
              </Typography>
            </div>
          </div>
        )}
    </div>
  );
}
