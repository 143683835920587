import { client } from "../client";

type ChangePasswordRequestDTO = {
  code: string;
  email: string;
  newPassword: string;
};

export const changePassword = async (params: ChangePasswordRequestDTO) => {
  const res = await client.post(`/auth/local/reset-password/${params.code}`, {
    email: params.email,
    newPassword: params.newPassword,
  });

  return res.data;
};
