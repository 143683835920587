import { client } from "../client";

type ActivityAuthor = {
  id: string;
  firstName: string;
  lastName: string;
  experience: number;
  level: number;
  avatarUrl: string;
  team: {
    id: string;
    codeName: string;
  };
};

export type ProfileActivity = {
  id: string;
  type: string;
  experience: number;
  bonusExp: number;
  xpBoostId?: string;
  distance: number;
  duration: number;
  elevation: number;
  createdAt: string;
  author: ActivityAuthor;
};

type GetUserActivitiesRequestDTO = {
  userId: string;
};
export type GetUserActivitiesResponseDTO = ProfileActivity[];

export const getUserActivities = async (
  params: GetUserActivitiesRequestDTO
) => {
  const res = await client.get<GetUserActivitiesResponseDTO>(
    `/users/${params.userId}/activities`
  );

  return res.data;
};
