import axios from "axios";
import { toast } from "sonner";

export const API_BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.withmiles.eu"
    : "http://localhost:9000";

export const client = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

client.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken !== "") {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// If an error occurs, display an error toast.
// If a request fails with a 401, redirect to the login page.
client.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      localStorage.setItem("accessToken", "");

      window.location.href = "/login";
    } else {
      const message = error.response?.data?.message ?? "Something went wrong.";

      toast("An error occured 🙁", {
        description: message,
      });
    }

    return Promise.reject(error);
  }
);
