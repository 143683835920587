import { client } from "../client";

type VerifyEmailRequestDTO = {
  code: string;
};

export const verifyEmail = async (params: VerifyEmailRequestDTO) => {
  const res = await client.post("/auth/local/verify", {
    code: params.code,
  });

  return res.data;
};
