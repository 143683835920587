import { LogOutIcon } from "lucide-react";
import { useAuthStore } from "../../stores/auth.store";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";

export const LogoutDialog = () => {
  const logout = useAuthStore((store) => store.logout);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="destructive" className="w-32 rounded-md">
          <LogOutIcon className="size-5 accent-red-400 mr-2" />
          Log out
        </Button>
      </DialogTrigger>
      <DialogContent className="w-[90vw] max-w-md mx-auto">
        <DialogHeader>
          <DialogTitle>Logging out</DialogTitle>
          <DialogDescription>
            Are you sure you want to log out?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="sm:flex-row flex-col mt-4">
          <DialogClose asChild>
            <Button
              variant="secondary"
              className="sm:mr-2 mb-2 sm:mb-0 w-full sm:w-auto"
            >
              Cancel
            </Button>
          </DialogClose>
          <Button
            variant="destructive"
            onClick={logout}
            className="w-full sm:w-auto"
          >
            Log out
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
