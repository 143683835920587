import { client } from "../client";

type JoinCompanyByCodeRequestDTO = {
  joinCode: string;
};
type JoinCompanyByCodeResponseDTO = {
  companyId: string;
};

export const joinCompanyByCode = async (
  params: JoinCompanyByCodeRequestDTO
) => {
  const res = await client.post<JoinCompanyByCodeResponseDTO>(
    "/companies/join",
    { ...params }
  );

  return res.data;
};
