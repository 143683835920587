import { client } from "../client";

export type UserSearchResult = {
  id: string;
  avatarUrl?: string;
  firstName: string;
  lastName: string;
  company: {
    name: string;
  };
  level: number;
};

export type SearchUsersRequestDTO = {
  name: string;
};
export type SearchUsersResponseDTO = UserSearchResult[];

export const searchUsers = async (params: SearchUsersRequestDTO) => {
  const res = await client.get<SearchUsersResponseDTO>(
    `/friends?name=${params.name}`
  );

  return res.data;
};
