import { client } from "../client";

type JoinTeamRequestDTO = {
  companyId: string;
  teamId: string;
};

export const joinTeam = async (params: JoinTeamRequestDTO) => {
  const res = await client.post(
    `/companies/${params.companyId}/teams/${params.teamId}/join`
  );

  return res.data;
};
