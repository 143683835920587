import { client } from "../client";
import { Activity } from "./get-home";

type UploadActivityRequestDTO = {
  file: File;
};
type UploadActivityResponseDTO = Activity;

export const uploadActivity = async (params: UploadActivityRequestDTO) => {
  const formData = new FormData();
  formData.append("file", params.file);

  const res = await client.post<UploadActivityResponseDTO>(
    `/activities`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return res.data;
};
