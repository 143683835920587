export const getIconBySportType = (type: string) => {
  if (type === "biking") {
    return "🚴‍♂️";
  }

  if (type === "running") {
    return "🏃";
  }

  if (type === "swimming") {
    return "🏊";
  }

  return "🚶";
};

export type PaceUnit = "KM/H" | "MIN/KM" | "MIN/100M";

export const getPaceFormatBySportType = (type: string): PaceUnit => {
  if (type === "biking") {
    return "KM/H";
  }

  if (type === "running") {
    return "MIN/KM";
  }

  if (type === "swimming") {
    return "MIN/100M";
  }

  return "MIN/KM";
};
