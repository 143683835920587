import { Paintbrush } from "lucide-react";
import { useMemo } from "react";
import { ProfileBackground } from "../../data/profile/get-backgrounds";
import { Button } from "./button";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./tabs";

export function GradientPicker({
  background,
  setBackground,
  setBackgroundId,
  backgroundChoices,
}: {
  background: string;
  setBackground: (background: string) => void;
  setBackgroundId: (backgroundId: string) => void;
  className?: string;
  backgroundChoices: ProfileBackground[];
}) {
  const solids = backgroundChoices.filter((b) => b.type === "solid");
  const gradients = backgroundChoices.filter((b) => b.type === "gradient");
  const images = backgroundChoices.filter((b) => b.type === "image");

  const defaultTab = useMemo(() => {
    if (background.includes("url")) return "image";
    if (background.includes("gradient")) return "gradient";
    return "solid";
  }, [background]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="rounded-md bg-white opacity-80 hover:opacity-100"
          // onClick={() => navigate(href)}
        >
          <Paintbrush className="size-4" />
        </Button>
      </PopoverTrigger>

      <PopoverContent className="w-64">
        <Tabs defaultValue={defaultTab} className="w-full">
          <TabsList className="mb-4 w-full">
            <TabsTrigger className="flex-1" value="solid">
              Solid
            </TabsTrigger>
            <TabsTrigger className="flex-1" value="gradient">
              Gradient
            </TabsTrigger>
            <TabsTrigger className="flex-1" value="image">
              Image
            </TabsTrigger>
          </TabsList>

          <TabsContent value="solid" className="mt-0 flex flex-wrap gap-1">
            {solids.map((s) => (
              <div
                key={s.id}
                style={{ background: s.style }}
                className="h-6 w-6 cursor-pointer rounded-md active:scale-105"
                onClick={() => {
                  setBackgroundId(s.id);
                  setBackground(s.style);
                }}
              />
            ))}
          </TabsContent>

          <TabsContent value="gradient" className="mt-0">
            <div className="mb-2 flex flex-wrap gap-1">
              {gradients.map((s) => (
                <div
                  key={s.id}
                  style={{ background: s.style }}
                  className="h-6 w-6 cursor-pointer rounded-md active:scale-105"
                  onClick={() => {
                    setBackgroundId(s.id);
                    setBackground(s.style);
                  }}
                />
              ))}
            </div>
          </TabsContent>

          <TabsContent value="image" className="mt-0">
            <div className="mb-2 grid grid-cols-2 gap-1">
              {images.map((s) => (
                <div
                  key={s.id}
                  style={{ backgroundImage: s.style }}
                  className="h-12 w-full cursor-pointer rounded-md bg-cover bg-center active:scale-105"
                  onClick={() => {
                    setBackgroundId(s.id);
                    setBackground(s.style);
                  }}
                />
              ))}
            </div>
          </TabsContent>
        </Tabs>
      </PopoverContent>
    </Popover>
  );
}
