import { Card } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import Typography from "../typography";

interface UpgradeButtonProps {
  name: string;
  description: string;
  valueUnit?: string;
  currentLevelValue: number;
  currentLevel: number;
  nextLevelValue: number;
  nextLevelCost: number;
  maxLevel: number;
  userSweatDrops: number;
  onUpgrade: () => void;
  disabled: boolean;
  cardStyle: string;
}

export function UpgradeButton({
  name,
  description,
  valueUnit,
  currentLevelValue,
  currentLevel,
  nextLevelValue,
  nextLevelCost,
  maxLevel,
  userSweatDrops,
  onUpgrade,
  disabled,
  cardStyle,
}: UpgradeButtonProps) {
  const isMaxLevel = currentLevel >= maxLevel;
  const canAfford = userSweatDrops >= nextLevelCost;
  const isDisabled = disabled || isMaxLevel || !canAfford;

  const Description = () => {
    const [left, ...right] = description.split(currentLevelValue.toString());

    // Small hack because split splits at *every* separator,
    // which removes the separator from the string.
    // Happens when 0 is the current level value, and upgrades go 20/40...
    const rest = right.join(currentLevelValue.toString());

    return (
      <div className="flex flex-col">
        <p className="text-sm text-muted-foreground">
          {left}
          <b className="text-blue-400">{currentLevelValue}</b>
          {rest}
        </p>
      </div>
    );
  };

  return (
    <div className="flex space-x-4 max-w-2xl">
      <Card
        onClick={isDisabled ? undefined : onUpgrade}
        className={`p-2 rounded-md cursor-pointer transition-colors w-28 h-28 flex flex-col justify-between ${
          isDisabled ? "bg-gray-100 cursor-not-allowed" : "hover:bg-gray-50"
        } ${cardStyle}`}
      >
        <span className="text-sm font-medium text-center">
          {isMaxLevel
            ? `${currentLevelValue}${valueUnit || ""}`
            : `${currentLevelValue}${valueUnit || ""} → ${nextLevelValue}${
                valueUnit || ""
              }`}
        </span>

        {isMaxLevel ? (
          <Typography variant="p" affects="muted" className={`text-center`}>
            MAX LEVEL
          </Typography>
        ) : (
          <Typography
            variant="p"
            affects="muted"
            className={`text-center ${
              nextLevelCost > userSweatDrops ? "text-red-400" : ""
            }`}
          >
            {nextLevelCost} 💧
          </Typography>
        )}

        <div className="w-full flex flex-col-reverse">
          {Array(maxLevel)
            .fill(null)
            .map((_, index) => (
              <Progress
                key={index}
                value={index < currentLevel ? 100 : 0}
                className={`w-full h-2 ${index === maxLevel - 1 ? "" : "mt-1"}`}
              />
            ))}
        </div>
      </Card>
      <div className="flex-1 flex flex-col justify-between">
        <div>
          <h3 className="text-lg font-bold uppercase">{name}</h3>
          <Description />
        </div>
      </div>
    </div>
  );
}
