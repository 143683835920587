import { client } from "../client";

type ResetPasswordRequestDTO = {
  email: string;
};

export const resetPassword = async (params: ResetPasswordRequestDTO) => {
  const res = await client.post("/auth/local/reset-password", {
    email: params.email,
  });

  return res.data;
};
