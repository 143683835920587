import { client } from "../../client";

type ClaimCellRequestDTO = {
  gridId: string;
  x: number;
  y: number;
};

type ClaimCellResponseDTO = {
  onClaimEnergyReward: number;
  onClaimExpBoostReward: number;
  energyRequirement: number;
};

export const claimCell = async (params: ClaimCellRequestDTO) => {
  const res = await client.post<ClaimCellResponseDTO>(
    `/grid-reveal/${params.gridId}/claim`,
    {
      x: params.x,
      y: params.y,
    }
  );

  return res.data;
};
