import { client } from "../client";
import { TrainingPlan, TrainingPlanType } from "./types";

type CreateTrainingPlanRequestDTO = {
  type: TrainingPlanType;
  startDate: Date;
};

type CreateTrainingPlanResponseDTO = TrainingPlan;

export const createTrainingPlan = async (
  params: CreateTrainingPlanRequestDTO
) => {
  const res = await client.post<CreateTrainingPlanResponseDTO>(
    "/training-plans",
    params
  );

  return res.data;
};
