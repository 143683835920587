import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { applyXPBoost } from "../../data/activities/apply-xp-boost";
import { getUserXPBoosts } from "../../data/activities/get-xp-boosts";
import { timeUntil } from "../../lib/date-utils";

type ApplyXPBoostProps = {
  activityId: string;
  activityExp: number;
  activityCreatedAt: string;
};

type XPBoost = {
  id: string;
  value: number;
};

export function ApplyXPBoost(props: ApplyXPBoostProps) {
  const navigate = useNavigate();

  const [boosts, setBoosts] = useState<XPBoost[]>([]);
  const [selectedBoostId, setSelectedBoostId] = useState<string | null>(null);

  const loadUserXPBoosts = async () => {
    const data = await getUserXPBoosts();
    const unusedBoosts = data.boosts
      .filter((xpb) => !xpb.activityId && !xpb.forgedIntoId)
      .sort((a, b) => b.value - a.value);

    setBoosts(unusedBoosts);
  };

  const onClickApplyXPBoost = async () => {
    await applyXPBoost({
      xpBoostId: selectedBoostId!,
      activityId: props.activityId,
    });

    navigate(0);
  };

  const getXPBoostValue = () => {
    return boosts.find((b) => b.id === selectedBoostId)?.value;
  };

  const calculateXPGain = () => {
    if (!selectedBoostId) {
      return 0;
    }

    const boost = getXPBoostValue();
    return boost ? Math.round((props.activityExp * boost) / 100) : 0;
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          size="xs"
          className="bg-blue-400 hover:bg-blue-500"
          onClick={loadUserXPBoosts}
        >
          Apply boost
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold text-black">
            Apply XP Boost
          </DialogTitle>

          <DialogDescription>
            Activity locks{" "}
            {timeUntil(dayjs(props.activityCreatedAt).add(1, "day").toString())}
            .
            <br />
            XP Bonuses are not applied to leaderboards.
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-row gap-2 w-full">
          {boosts.length > 0 ? (
            <>
              <Select onValueChange={(value) => setSelectedBoostId(value)}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select a boost" />
                </SelectTrigger>
                <SelectContent>
                  {boosts.map((boost) => (
                    <SelectItem key={boost.id} value={boost.id}>
                      {boost.value}% Boost
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <div className="text-sm h-full w-4/5 text-center justify-center items-center content-center">
                {selectedBoostId
                  ? `${getXPBoostValue()}% of ${props.activityExp} = `
                  : ""}

                <span className="font-bold text-blue-400">
                  {calculateXPGain()} XP
                </span>
              </div>
            </>
          ) : (
            <p>No available XP boosts</p>
          )}
        </div>

        <Button
          className="w-full bg-blue-400 hover:bg-blue-500 text-white"
          disabled={!selectedBoostId}
          onClick={onClickApplyXPBoost}
        >
          Apply Boost
        </Button>
      </DialogContent>
    </Dialog>
  );
}
