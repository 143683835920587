import { useMediaQuery } from "@uidotdev/usehooks";
import { Tooltip, TooltipContent, TooltipTrigger } from "../tooltip";

const zones = [
  { name: "Zone 1", color: "bg-gray-400" },
  { name: "Zone 2", color: "bg-green-400" },
  { name: "Zone 3", color: "bg-blue-400" },
  { name: "Zone 4", color: "bg-amber-500" },
  { name: "Zone 5", color: "bg-red-400" },
];

interface HeartRateZoneChartProps {
  selectedZone?: number;
  zoneTooltip: string;
}

export const HeartRateZoneChart = ({
  selectedZone,
  zoneTooltip,
}: HeartRateZoneChartProps) => {
  const isSmallDevice = useMediaQuery("only screen and (max-width: 768px)");

  return (
    <div>
      <div
        className={`flex justify-between mb-2 ${
          isSmallDevice ? "w-64" : "w-80"
        }`}
      >
        {zones.map((zone, index) => (
          <div key={zone.name} className="text-center w-1/5 px-1">
            {selectedZone === index + 1 && (
              <Tooltip>
                <TooltipTrigger className="cursor-help text-xs">
                  {zone.name}
                </TooltipTrigger>

                <TooltipContent className="bg-white text-black border border-gray-200">
                  <p className="max-w-xs">{zoneTooltip}</p>
                </TooltipContent>
              </Tooltip>
            )}
          </div>
        ))}
      </div>

      <div className={`flex space-x-0.5 ${isSmallDevice ? "w-64" : "w-80"}`}>
        {zones.map((zone, index) =>
          selectedZone === index + 1 ? (
            <Tooltip key={zone.name + index}>
              <TooltipTrigger asChild className="cursor-help text-xs">
                <div
                  key={zone.name}
                  className={`flex-1 h-2 ${zone.color} rounded-md transition-all duration-300 ease-in-out ring-1 ring-offset-1 ring-blue-600 shadow-lg}`}
                  aria-label={zone.name}
                />
              </TooltipTrigger>

              <TooltipContent className="bg-white text-black border border-gray-200">
                <p className="max-w-xs">{zoneTooltip}</p>
              </TooltipContent>
            </Tooltip>
          ) : (
            <div
              key={zone.name + index}
              className={`flex-1 h-2 ${zone.color} rounded-md transition-all duration-300 ease-in-out opacity-40`}
              aria-label={zone.name}
            />
          )
        )}
      </div>
    </div>
  );
};
