import { client } from "../client";

export type XPBoost = {
  id: string;
  value: number;
  source: string;
  activityId?: string;
  forgedIntoId?: string;
  createdAt: string;
};

export type GetUserXPBoostsResponseDTO = {
  userEnergy: number;
  boosts: XPBoost[];
};

export const getUserXPBoosts = async () => {
  const res = await client.get<GetUserXPBoostsResponseDTO>(`/xp-boosts`);

  return res.data;
};
