import { useMediaQuery } from "@uidotdev/usehooks";
import { ArrowLeft } from "lucide-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserProfileHeader } from "../components/cards/user-profile-header";
import { Button } from "../components/ui/button";
import { FriendList } from "../components/ui/friends/friend-list";
import { FriendRequestsList } from "../components/ui/friends/friend-requests-list";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { User, getUserById } from "../data/auth/get-user";
import { useAuthStore } from "../stores/auth.store";

export const Friends = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const isSmallDevice = useMediaQuery("only screen and (max-width: 768px)");

  const loggedInUserId = useAuthStore((store) => store.userId);

  const userId = params?.userId || loggedInUserId;

  const [user, setUser] = useState<User>();

  useEffect(() => {
    const loadUserAndStats = async () => {
      const data = await getUserById({
        userId,
      });

      setUser(data);
    };

    loadUserAndStats();
  }, [userId]);

  const onClickBack = () => {
    const backURL =
      userId === loggedInUserId ? "/profile" : `/profile/${userId}`;

    return navigate(backURL);
  };

  return (
    <div className="flex flex-col gap-4">
      <UserProfileHeader
        avatarUrl={user?.avatarUrl || ""}
        backgroundStyle={user?.profileBackground?.style || ""}
        experience={user?.experience || 0}
        firstName={user?.firstName || ""}
        lastName={user?.lastName || ""}
        level={user?.level || 0}
        teamName={user?.team?.name || ""}
        teamCodeName={user?.team?.codeName || ""}
      />

      {loggedInUserId !== userId && (
        <div className="w-full lg:w-1/2 flex flex-col gap-2">
          <Button className="w-min" variant="secondary" onClick={onClickBack}>
            <ArrowLeft className="mr-2 h-4 w-4" /> Back
          </Button>

          <FriendList userId={userId} />
        </div>
      )}

      {isSmallDevice && loggedInUserId === userId && (
        <Tabs
          defaultValue={location.state?.defaultTab || "list"}
          className="flex flex-col w-full items-center"
        >
          <TabsList className="w-fit">
            <TabsTrigger value="list">Friends</TabsTrigger>
            <TabsTrigger value="add">Add friends</TabsTrigger>
          </TabsList>

          <TabsContent value="list" className="w-full">
            <FriendList userId={userId} />
          </TabsContent>

          <TabsContent value="add" className="w-full">
            <FriendRequestsList />
          </TabsContent>
        </Tabs>
      )}

      {!isSmallDevice && loggedInUserId === userId && (
        <div className="flex flex-row gap-4 w-full h-full">
          <FriendList userId={userId} />
          <FriendRequestsList />
        </div>
      )}
    </div>
  );
};
