import { client } from "../client";
import { Activity } from "./get-home";

type UploadManualActivityRequestDTO = {
  distance: number;
  duration: number;
  sportType: string;
  date: string;
};
type UploadManualActivityResponseDTO = Activity;

export const uploadManualActivity = async (
  params: UploadManualActivityRequestDTO
) => {
  const res = await client.post<UploadManualActivityResponseDTO>(
    `/activities/manual`,
    { ...params }
  );

  return res.data;
};
