import { useEffect, useState } from "react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { ForgeHistory } from "../components/ui/xp-forge/forge-history";
import { ForgeTab } from "../components/ui/xp-forge/forge-tab";
import { UpgradesTab } from "../components/ui/xp-forge/xpf-upgrades-tab";
import { getUserXPBoosts, XPBoost } from "../data/activities/get-xp-boosts";
import {
  getMinigameUpgrades,
  MinigameUpgrade,
} from "../data/minigames/get-upgrades";
import { ProcessedBoost, processXPBoosts } from "../lib/xp-forge-utils";
import { useAuthStore } from "../stores/auth.store";

export const XPForge = () => {
  const userId = useAuthStore((store) => store.userId);

  const [boosts, setBoosts] = useState<XPBoost[]>([]);
  const [forgedBoosts, setForgedBoosts] = useState<ProcessedBoost[]>([]);
  const [upgrades, setUpgrades] = useState<MinigameUpgrade[]>([]);

  const [userSweatDrops, setUserSweatDrops] = useState<number>(0);
  const [userEnergy, setUserEnergy] = useState<number>(0);

  const loadMinigameUpgrades = async (userId: string) => {
    const data = await getMinigameUpgrades({
      userId,
    });

    const sortedUpgrades = data.upgrades
      .sort((u1, u2) => u1.upgradeType.localeCompare(u2.upgradeType))
      .filter((u) => u.minigameId === "XP_FORGE");

    setUpgrades(sortedUpgrades);
    setUserSweatDrops(data.userSweatDrops);
  };

  const loadBoosts = async () => {
    const data = await getUserXPBoosts();

    setUserEnergy(data.userEnergy);

    const sortedBoosts = data.boosts
      .sort((a, b) => b.value - a.value)
      .filter((xpb) => !xpb.activityId && !xpb.forgedIntoId);

    setBoosts(sortedBoosts);

    const processedBoosts = processXPBoosts(data.boosts);

    setForgedBoosts(processedBoosts);
  };

  useEffect(() => {
    loadBoosts();
    loadMinigameUpgrades(userId);
  }, [userId]);

  return (
    <div className="w-full h-full">
      {/* Desktop layout */}
      <div className="hidden md:flex gap-4 w-full h-full">
        <div className="w-2/3 bg-white border border-gray-200 rounded-md">
          <ForgeTab
            boosts={boosts}
            upgrades={upgrades}
            loadBoosts={loadBoosts}
            userEnergy={userEnergy}
          />
        </div>

        <div className="w-1/3 h-full flex flex-col gap-4">
          <div className="h-full bg-white border border-gray-200 rounded-md">
            <UpgradesTab
              upgrades={upgrades}
              userSweatDrops={userSweatDrops}
              loadMinigameUpgrades={() => loadMinigameUpgrades(userId)}
            />
          </div>

          <div className="h-full bg-white border border-gray-200 rounded-md overflow-hidden">
            <ForgeHistory forgedBoosts={forgedBoosts} />
          </div>
        </div>
      </div>

      {/* Mobile layout */}
      <div className="md:hidden w-full h-full">
        <Tabs defaultValue="xpforge" className="w-full">
          <TabsList className="w-full">
            <TabsTrigger value="xpforge" className="w-1/2">
              XP Forge
            </TabsTrigger>

            <TabsTrigger value="upgrades" className="w-1/2">
              Upgrades
            </TabsTrigger>

            <TabsTrigger value="history" className="w-1/2">
              History
            </TabsTrigger>
          </TabsList>

          <TabsContent value="xpforge">
            <ForgeTab
              boosts={boosts}
              upgrades={upgrades}
              loadBoosts={loadBoosts}
              userEnergy={userEnergy}
            />
          </TabsContent>

          <TabsContent value="upgrades">
            <UpgradesTab
              upgrades={upgrades}
              userSweatDrops={userSweatDrops}
              loadMinigameUpgrades={() => loadMinigameUpgrades(userId)}
            />
          </TabsContent>

          <TabsContent value="history">
            <ForgeHistory forgedBoosts={forgedBoosts} />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};
