import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp";
import { CheckCheck } from "lucide-react";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { sendEmailVerificationCode } from "../../../data/auth/send-verify-email";
import { verifyEmail } from "../../../data/auth/verify-email";
import { useAuthStore } from "../../../stores/auth.store";
import { RewardXPBoost } from "../../dialogs/reward-xp-boost-dialog";
import { Button } from "../button";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "../input-otp";
import { Label } from "../label";

export const EmailVerification = () => {
  const isVerified = useAuthStore((store) => store.isVerified);
  const setIsVerified = useAuthStore((store) => store.setIsVerified);

  const [code, setCode] = useState<string>("");
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [verified, setVerified] = useState<boolean>(false);
  const [showRewardModal, setShowRewardModal] = useState<boolean>(false);

  useEffect(() => {
    setVerified(isVerified);

    if (isVerified) {
      setEmailSent(true);
    }
  }, [isVerified]);

  const sendVerificationEmail = async () => {
    await sendEmailVerificationCode();

    setEmailSent(true);
  };

  const verifyCode = async () => {
    if (code.length !== 6) {
      toast("Code must be 6-digits.");
      return;
    }

    await verifyEmail({
      code,
    });

    setVerified(true);
    setIsVerified(true);
    setShowRewardModal(true);
  };

  return (
    <div className="w-full space-y-4 rounded-lg border p-3 shadow-sm">
      <div className="flex items-center justify-between">
        <div className="space-y-0.5">
          <Label className="text-lg font-semibold">Email Verification</Label>
          <p className="text-sm text-gray-600">
            Secure your account by verifying your email address.
          </p>
        </div>

        {verified && (
          <div className="flex items-center justify-center ml-4">
            <CheckCheck className="w-12 h-12 text-blue-400" />
          </div>
        )}
      </div>

      {showRewardModal && <RewardXPBoost percentage={100} />}

      {!emailSent && (
        <Button onClick={sendVerificationEmail}>Send Verification Email</Button>
      )}

      {emailSent && !verified && (
        <>
          <p className="text-sm text-gray-600">
            Enter the 6-digit code sent to your email:
          </p>

          <InputOTP
            value={code}
            onChange={setCode}
            maxLength={6}
            pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
          >
            <InputOTPGroup>
              <InputOTPSlot index={0} />
              <InputOTPSlot index={1} />
              <InputOTPSlot index={2} />
              <InputOTPSlot index={3} />
              <InputOTPSlot index={4} />
              <InputOTPSlot index={5} />
            </InputOTPGroup>
          </InputOTP>

          <Button onClick={verifyCode} disabled={code.length !== 6}>
            Verify Code
          </Button>
        </>
      )}
    </div>
  );
};
