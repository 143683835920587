import Typography from "../typography";
import { Separator } from "../ui/separator";

type UserTotalDistancesProps = {
  bikingDistance: number;
  runningDistance: number;
  walkingDistance: number;
  swimmingDistance: number;
};

export const UserTotalDistances = (props: UserTotalDistancesProps) => {
  const activities = [
    { name: "BIKING", distance: props.bikingDistance },
    { name: "RUNNING", distance: props.runningDistance },
    { name: "WALKING", distance: props.walkingDistance },
    { name: "SWIMMING", distance: props.swimmingDistance },
  ];

  const totalDistance = activities.reduce(
    (sum, activity) => sum + activity.distance,
    0
  );

  const sortedActivities = activities.sort((a, b) => b.distance - a.distance);

  return (
    <div className="h-fit w-full bg-white rounded-lg border border-slate-200 p-4">
      <Typography variant="h3">Total distance</Typography>
      <Typography variant="p" affects="muted" className="text-sm">
        In kilometers
      </Typography>

      {sortedActivities.map((activity, index) => (
        <div key={index} className="flex flex-col gap-1 mt-4">
          <Typography variant="h1" className="text-primary">
            {Math.round(activity.distance)}
          </Typography>

          <div className="flex flex-row w-full justify-between">
            <Typography variant="p" affects="muted">
              {activity.name}
            </Typography>
            <Typography variant="p" affects="muted">
              {Math.round((activity.distance / totalDistance) * 100)}%
            </Typography>
          </div>

          <Separator />
        </div>
      ))}
    </div>
  );
};
