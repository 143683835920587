import { client } from "../client";
import { ProfileBackground } from "../profile/get-backgrounds";

export type TeamMember = {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
};

export type Team = {
  id: string;
  name: string;
  codeName: string;
  members: TeamMember[];
};

export type Company = {
  id: string;
  name: string;
  avatarUrl: string;
  level: number;
  teams: Team[];
  joinCode?: string;
  profileBackground?: ProfileBackground;
};

type GetCompanyRequestDTO = {
  companyId: string;
};
type GetCompanyResponseDTO = Company;

export const getCompany = async (params: GetCompanyRequestDTO) => {
  const res = await client.get<GetCompanyResponseDTO>(
    `/companies/${params.companyId}`
  );

  return res.data;
};
