export type MinigameId = "GRID_REVEAL" | "XP_FORGE";

export const getDisplayName = (minigameId: string) => {
  if (minigameId === "GRID_REVEAL") {
    return "Grid Reveal";
  }

  if (minigameId === "XP_FORGE") {
    return "XP Forge";
  }

  if (minigameId === "EMAIL_VERIFICATION") {
    return "Email Verification";
  }

  return "";
};
