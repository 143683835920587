export const getDisplayName = (firstName: string, lastName?: string) => {
  if (!lastName) {
    return firstName;
  }

  return `${firstName} ${lastName.charAt(0)}.`;
};

export const getFallbackName = (
  firstName: string,
  lastName?: string
): string => {
  if (!lastName) {
    const [left, right] = firstName.split(" ");

    if (!right) {
      return left.charAt(0);
    }

    return getFallbackName(left, right);
  }

  return `${firstName.charAt(0)}${lastName.charAt(0)}`;
};
