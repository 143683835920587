import { buyMinigameUpgrade } from "../../../data/minigames/buy-upgrade";
import { MinigameUpgrade } from "../../../data/minigames/get-upgrades";
import {
  getUpgradeInfo,
  sortUpgrades,
} from "../../../lib/minigame-upgrade-utils";
import Typography from "../../typography";
import { Badge } from "../badge";
import { ScrollArea } from "../scroll-area";
import { UpgradeButton } from "../upgrade-button";

type UpgradesTabProps = {
  upgrades: MinigameUpgrade[];
  userSweatDrops: number;
  loadMinigameUpgrades: () => Promise<void>;
};

export const UpgradesTab = ({
  upgrades,
  userSweatDrops,
  loadMinigameUpgrades,
}: UpgradesTabProps) => {
  const handleUpgrade = async (upgradeId: string) => {
    await buyMinigameUpgrade({
      upgradeId,
    });

    await loadMinigameUpgrades();
  };

  return (
    <div className="h-full bg-white p-4 flex flex-col gap-4">
      <Typography variant="h2" affects="removePMargin">
        Upgrades
      </Typography>

      <Badge
        variant="secondary"
        className="bg-blue-400 bg-opacity-10 text-md w-fit"
      >
        <Typography variant="p" affects="muted" className="text-blue-400">
          {userSweatDrops} left 💧
        </Typography>
      </Badge>

      <ScrollArea>
        <div className="h-max flex flex-col gap-4">
          {upgrades.sort(sortUpgrades("XP_FORGE")).map((u) => {
            const upgrade = getUpgradeInfo(u.minigameId, u.upgradeType);

            return (
              <UpgradeButton
                key={u.id}
                name={upgrade?.title || ""}
                valueUnit={upgrade?.unit}
                description={upgrade?.description || ""}
                currentLevel={u.currentLevel}
                currentLevelValue={u.currentLevelValue}
                nextLevelCost={u.nextLevelCost}
                nextLevelValue={u.nextLevelValue}
                maxLevel={u.maxLevel}
                userSweatDrops={userSweatDrops}
                onUpgrade={() => handleUpgrade(u.id)}
                disabled={false}
                cardStyle={upgrade?.cardStyle || ""}
              />
            );
          })}
        </div>
      </ScrollArea>
    </div>
  );
};
