import { useNavigate } from "react-router-dom";
import { Grid } from "../../../data/minigames/grid-reveal/get-grid-by-id";
import { getFallbackName } from "../../../lib/name-utils";
import Typography from "../../typography";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../accordion";
import { Avatar, AvatarFallback, AvatarImage } from "../avatar";
import { Badge } from "../badge";

type InfoSectionProps = {
  grid: Grid | null;
  setHighlightedCell: (cell: { x: number; y: number } | null) => void;
};

export const InfoSection = ({ grid, setHighlightedCell }: InfoSectionProps) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white border border-gray-200 rounded-md p-4 h-full pb-20 md:pb-4">
      <Typography variant="h2">Info</Typography>

      {grid?.user && (
        <div className="flex items-center gap-2">
          <Avatar
            affects="noborder"
            className="hover:cursor-pointer"
            onClick={() => navigate(`/profile/${grid.user.id}`)}
          >
            <AvatarImage src={grid.user.avatarUrl} alt="Avatar" />
            <AvatarFallback>
              {getFallbackName(grid.user.firstName, grid.user.lastName)}
            </AvatarFallback>
          </Avatar>

          <div>
            <Typography
              variant="p"
              affects="link"
              onClick={() => navigate(`/profile/${grid.user.id}`)}
            >
              {grid.user.firstName} {grid.user.lastName}
            </Typography>
            <Badge
              variant="secondary"
              className="bg-blue-400 bg-opacity-10 text-md"
            >
              <Typography variant="p" affects="muted" className="text-blue-400">
                {grid.user.energy} left ⚡
              </Typography>
            </Badge>
          </div>
        </div>
      )}

      <Accordion
        type="multiple"
        className="w-full mt-4"
        defaultValue={["rules", "stats", "history"]}
      >
        <AccordionItem value="rules">
          <AccordionTrigger>Rules</AccordionTrigger>
          <AccordionContent>
            <ul className="list-disc pl-4">
              <li>Use Energy to claim cells on the grid</li>
              <li>Claiming cells rewards Energy or an XP Boost</li>
              <li>Claim all cells to advance to the next grid level</li>
              <li>Cells adjacent to claimed cells get a discount</li>
            </ul>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="stats">
          <AccordionTrigger>Stats</AccordionTrigger>
          <AccordionContent>
            <ul className="space-y-2 max-h-60 overflow-y-auto list-none">
              <li className="flex justify-between items-center">
                <span className="font-medium">XP Boost Stats</span>
                <div className="flex gap-2">
                  <Badge
                    variant="secondary"
                    className="bg-blue-400 bg-opacity-10"
                  >
                    Rewarded: {grid?.stats.totalXPBoostRewarded}%
                  </Badge>
                  <Badge
                    variant="secondary"
                    className="bg-blue-400 bg-opacity-10"
                  >
                    Cells: {grid?.stats.totalXPBoostCellsClaimed}
                  </Badge>
                </div>
              </li>

              <li className="flex justify-between items-center">
                <span className="font-medium">Energy Stats</span>
                <div className="flex gap-2">
                  <Badge
                    variant="secondary"
                    className="bg-blue-400 bg-opacity-10"
                  >
                    Rewarded: {grid?.stats.totalEnergyRewarded} ⚡
                  </Badge>
                  <Badge
                    variant="secondary"
                    className="bg-blue-400 bg-opacity-10"
                  >
                    Cells: {grid?.stats.totalEnergyCellsClaimed}
                  </Badge>
                </div>
              </li>

              <li className="flex justify-between items-center">
                <span className="font-medium">Energy Cost</span>
                <div className="flex gap-2">
                  <Badge
                    variant="secondary"
                    className="bg-blue-400 bg-opacity-10"
                  >
                    Avg: {grid?.stats.avgPricePerCell?.toFixed(2) || 0} ⚡
                  </Badge>
                  <Badge
                    variant="secondary"
                    className="bg-blue-400 bg-opacity-10"
                  >
                    Remaining: {grid?.stats.remainingCellsCost} ⚡
                  </Badge>
                </div>
              </li>

              <li className="flex justify-between items-center">
                <span className="font-medium">ROI</span>
                <div className="flex gap-2">
                  <Badge
                    variant="secondary"
                    className="bg-blue-400 bg-opacity-10"
                  >
                    Total paid: {grid?.stats.totalEnergyPaid || 0} ⚡
                  </Badge>
                  <Badge
                    variant="secondary"
                    className="bg-blue-400 bg-opacity-10"
                  >
                    Total price: {grid?.stats.totalEnergyCost || 0} ⚡
                  </Badge>
                </div>
              </li>
            </ul>
          </AccordionContent>
        </AccordionItem>

        <AccordionItem value="history">
          <AccordionTrigger>Rewards History</AccordionTrigger>
          <AccordionContent>
            <div className="space-y-2 max-h-60 overflow-y-auto">
              {grid?.claimedCellRewards.map((reward, index) => (
                <div
                  key={index}
                  className="p-2 bg-gray-100 rounded-md cursor-pointer hover:bg-gray-200 transition-colors duration-200"
                  onMouseEnter={() =>
                    setHighlightedCell({ x: reward.x, y: reward.y })
                  }
                  onMouseLeave={() => setHighlightedCell(null)}
                >
                  <span className="font-semibold">
                    Cell [{reward.x}, {reward.y}]
                  </span>{" "}
                  <span>
                    Cost:{" "}
                    {Math.round(
                      reward.energyRequirement * (1 - reward.discount / 100)
                    )}{" "}
                    ⚡
                  </span>{" "}
                  {reward.onClaimEnergyReward > 0 && (
                    <span>Reward: {reward.onClaimEnergyReward} ⚡</span>
                  )}
                  {reward.onClaimExpBoostReward > 0 && (
                    <span>
                      Reward: {reward.onClaimExpBoostReward}% XP Boost
                    </span>
                  )}
                </div>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
