export const initiateLinkWithStrava = async () => {
  const BASE_URL =
    process.env.NODE_ENV === "production"
      ? "https://withmiles.eu"
      : "http://localhost:5173";

  const redirectUrl = `${BASE_URL}/auth/strava`;
  const scope = "activity:read";
  const clientId = "116629";

  const url = new URL("http://www.strava.com/oauth/authorize");
  url.searchParams.append("client_id", clientId);
  url.searchParams.append("response_type", "code");
  url.searchParams.append("redirect_uri", `${redirectUrl}/exchange_token`);
  url.searchParams.append("approval_prompt", "force");
  url.searchParams.append("scope", scope);

  window.location.href = url.toString();
};
