import { client } from "../client";

type LeaveCompanyRequestDTO = {
  userId: string;
};

export const leaveCompany = async (params: LeaveCompanyRequestDTO) => {
  const res = await client.post(`/companies/leave`, {
    userId: params.userId,
  });

  return res.data;
};
