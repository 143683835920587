import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ZapIcon } from "lucide-react";
import { useState } from "react";
import Confetti from "react-confetti-boom";

type RewardXPBoostProps = {
  percentage: number;
};

export function RewardXPBoost(props: RewardXPBoostProps) {
  const { percentage } = props;

  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent>
        <DialogHeader>
          <Confetti
            mode="boom"
            particleCount={100}
            x={0.1}
            y={0}
            launchSpeed={2}
            shapeSize={20}
          />

          <DialogTitle className="text-2xl font-bold flex items-center justify-center gap-2">
            <ZapIcon className="h-6 w-6 text-yellow-400 animate-pulse" />
            XP Boost Received!
          </DialogTitle>

          <DialogDescription className="text-center pt-2">
            You've earned an XP Boost reward!
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-col items-center space-y-4">
          <div className="text-4xl font-bold text-primary">
            +{percentage}% XP
          </div>

          <p className="text-center text-sm text-muted-foreground">
            This XP Boost can be applied to your next activities to earn extra
            experience.
          </p>
        </div>

        <DialogFooter className="sm:justify-center">
          <Button onClick={() => setIsOpen(false)}>Awesome!</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
