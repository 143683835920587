import { GearIcon } from "@radix-ui/react-icons";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProfileBackgrounds,
  ProfileBackground,
} from "../../data/profile/get-backgrounds";
import { updateCompany } from "../../data/settings/update-company";
import { useAuthStore } from "../../stores/auth.store";
import Typography from "../typography";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Button } from "../ui/button";
import { GradientPicker } from "../ui/gradient-picker";

type CompanyProfileHeaderProps = {
  avatarUrl: string;
  name: string;
  level: number;
  backgroundStyle: string;
};

export function CompanyProfileHeader(props: CompanyProfileHeaderProps) {
  const params = useParams();
  const navigate = useNavigate();

  const isCompanyAdmin = useAuthStore((store) => store.isCompanyAdmin);
  const userCompanyId = useAuthStore((store) => store.companyId);

  const companyId = params?.companyId || userCompanyId;

  const [backgroundChoices, setBackgroundChoices] = useState<
    Array<ProfileBackground>
  >([]);
  const [backgroundId, setBackgroundId] = useState<string>("");
  const [background, setBackground] = useState(
    props.backgroundStyle || "linear-gradient(to bottom right,#accbee,#e7f0fd)"
  );

  useEffect(() => {
    const updateCompanyBackground = async () => {
      if (isCompanyAdmin && backgroundId !== "") {
        await updateCompany({
          companyId,
          backgroundId,
        });
      }
    };

    updateCompanyBackground();
  }, [isCompanyAdmin, companyId, backgroundId]);

  useEffect(() => {
    setBackground(
      props.backgroundStyle ||
        "linear-gradient(to bottom right,#accbee,#e7f0fd)"
    );
  }, [props.backgroundStyle]);

  useEffect(() => {
    const loadBackgrounds = async () => {
      const data = await getProfileBackgrounds();

      setBackgroundChoices(data);
    };

    loadBackgrounds();
  }, []);

  return (
    <div className="flex flex-col w-full">
      <div
        className="flex flex-row items-center gap-4 rounded-lg w-full !bg-cover transition-all relative p-4 md:h-30 md:items-end"
        style={{ background }}
      >
        <Avatar size="large" className="shrink-0">
          <AvatarImage src={props.avatarUrl} />
          <AvatarFallback>{props.name}</AvatarFallback>
        </Avatar>

        <div className="flex flex-col md:flex-row md:items-center md:gap-7 md:flex-grow md:bg-gray-800 md:rounded-lg md:bg-clip-padding md:backdrop-filter md:backdrop-blur-md md:bg-opacity-30 md:p-4">
          <div className="md:pr-4">
            <Typography className="text-white md:text-xl" variant="h4">
              {props.name}
            </Typography>

            <Typography className="text-white text-sm" variant="p">
              Level {props.level}
            </Typography>
          </div>
        </div>

        {isCompanyAdmin && (
          <div className="absolute bottom-2 right-2">
            <Button
              variant="ghost"
              size="icon"
              className="rounded-md bg-white opacity-80 hover:opacity-100 mr-2"
              onClick={() =>
                navigate("/settings", { state: { defaultTab: "company" } })
              }
            >
              <GearIcon className="size-4" />
            </Button>

            <GradientPicker
              background={background}
              setBackground={setBackground}
              setBackgroundId={setBackgroundId}
              backgroundChoices={backgroundChoices}
            />
          </div>
        )}
      </div>
    </div>
  );
}
