import { useEffect, useState } from "react";
import { buyMinigameUpgrade } from "../../../data/minigames/buy-upgrade";
import {
  getMinigameUpgrades,
  MinigameUpgrade,
} from "../../../data/minigames/get-upgrades";
import {
  getUpgradeInfo,
  sortUpgrades,
} from "../../../lib/minigame-upgrade-utils";
import Typography from "../../typography";
import { Badge } from "../badge";
import { ScrollArea } from "../scroll-area";
import { UpgradeButton } from "../upgrade-button";

type UpgradesSectionProps = {
  userId: string;
  canBuyUpgrade: boolean;
};

export const UpgradesSection = ({
  userId,
  canBuyUpgrade,
}: UpgradesSectionProps) => {
  const [userSweatDrops, setUserSweatDrops] = useState<number>(0);
  const [upgrades, setUpgrades] = useState<MinigameUpgrade[]>([]);

  const loadMinigameUpgrades = async (userId: string) => {
    const data = await getMinigameUpgrades({
      userId,
    });

    const sortedUpgrades = data.upgrades.filter(
      (u) => u.minigameId === "GRID_REVEAL"
    );

    setUpgrades(sortedUpgrades);
    setUserSweatDrops(data.userSweatDrops);
  };

  useEffect(() => {
    loadMinigameUpgrades(userId);
  }, [userId]);

  const handleUpgrade = async (upgradeId: string) => {
    await buyMinigameUpgrade({
      upgradeId,
    });

    await loadMinigameUpgrades(userId);
  };

  return (
    <div className="bg-white border border-gray-200 rounded-md p-4 h-full flex flex-col gap-2 pb-20 md:pb-4">
      <Typography variant="h2" affects="removePMargin">
        Upgrades
      </Typography>

      <Badge
        variant="secondary"
        className="bg-blue-400 bg-opacity-10 text-md w-fit"
      >
        <Typography variant="p" affects="muted" className="text-blue-400">
          {userSweatDrops} left 💧
        </Typography>
      </Badge>

      <ScrollArea>
        <div className="h-max flex flex-col gap-4">
          {upgrades.sort(sortUpgrades("GRID_REVEAL")).map((u) => {
            const upgrade = getUpgradeInfo(u.minigameId, u.upgradeType);

            return (
              <UpgradeButton
                key={u.id}
                name={upgrade?.title || ""}
                valueUnit={upgrade?.unit}
                description={upgrade?.description || ""}
                currentLevel={u.currentLevel}
                currentLevelValue={u.currentLevelValue}
                nextLevelCost={u.nextLevelCost}
                nextLevelValue={u.nextLevelValue}
                maxLevel={u.maxLevel}
                userSweatDrops={userSweatDrops}
                onUpgrade={() => handleUpgrade(u.id)}
                disabled={!canBuyUpgrade}
                cardStyle={upgrade?.cardStyle || ""}
              />
            );
          })}
        </div>
      </ScrollArea>
    </div>
  );
};
