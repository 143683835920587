import { client } from "../client";

export type FriendRequested = {
  id: string;
  avatarUrl?: string;
  firstName: string;
  lastName: string;
  company: {
    name: string;
  };
  level: number;
};

export type FriendRequest = {
  id: string;
  createdAt: string;
  sender: FriendRequested;
  receiver: FriendRequested;
};

export type GetFriendRequestsResponseDTO = FriendRequest[];

export const getPendingFriendRequests = async () => {
  const res = await client.get<GetFriendRequestsResponseDTO>(
    `/friends/pending`
  );

  return res.data;
};
