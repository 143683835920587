import { LeaveCompanyDialog } from "../../dialogs/leave-company-dialog";
import Typography from "../../typography";
import { Separator } from "../separator";

export const DangerZoneTab = () => {
  return (
    <div className="w-full bg-white rounded-lg border border-slate-200 p-4 flex flex-col gap-4">
      <div>
        <Typography variant="h3">Danger Zone</Typography>
        <Typography variant="p" affects="muted" className="text-sm">
          Irreversible actions that have a bigger impact.
        </Typography>
      </div>

      <Separator />

      <LeaveCompanyDialog />
    </div>
  );
};
