import { client } from "../client";

export type Friend = {
  id: string;
  avatarUrl?: string;
  firstName: string;
  lastName: string;
  company: {
    name: string;
  };
  level: number;
};

export type GetFriendsRequestDTO = {
  userId: string;
};
export type GetFriendsResponseDTO = Friend[];

export const getFriends = async (params: GetFriendsRequestDTO) => {
  const res = await client.get<GetFriendsResponseDTO>(
    `/friends/${params.userId}`
  );

  return res.data;
};
