import { CheckIcon, SendHorizonal, XIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Friend } from "../../../data/profile/get-friends";
import { getFallbackName } from "../../../lib/name-utils";
import Typography from "../../typography";
import { Avatar, AvatarFallback, AvatarImage } from "../avatar";
import { Badge } from "../badge";
import { Button } from "../button";

type FriendCardProps = {
  friend: Friend;
  badgeText?: string;
  onClickRemove?: () => void;
  onClickAccept?: () => void;
  onClickSend?: () => void;
};

export const FriendCard = (props: FriendCardProps) => {
  const { friend, badgeText, onClickRemove, onClickAccept, onClickSend } =
    props;

  const navigate = useNavigate();

  return (
    <div className="flex flex-row bg-white rounded-lg border border-slate-200 p-2 justify-between items-center">
      <div className="flex flex-row gap-2 items-center">
        <Avatar
          className="h-10 w-10 hover:cursor-pointer"
          onClick={() => navigate(`/profile/${friend.id}`)}
        >
          <AvatarImage src={friend.avatarUrl} />
          <AvatarFallback>
            {getFallbackName(friend.firstName, friend.lastName)}
          </AvatarFallback>
        </Avatar>

        <div>
          <Typography
            variant="h3"
            affects="link"
            className="text-sm font-semibold"
            onClick={() => navigate(`/profile/${friend.id}`)}
          >
            {friend.firstName} {friend.lastName}
          </Typography>

          <p className="text-xs text-muted-foreground">
            {friend.company?.name && `${friend.company.name} •`} Level{" "}
            {friend.level}
          </p>
        </div>
      </div>

      {/* Actions */}
      <div className="flex flex-row gap-2">
        {badgeText && (
          <Badge className="bg-blue-400 hover:bg-blue-400">{badgeText}</Badge>
        )}

        {onClickRemove && (
          <Button
            variant="outline"
            size="icon"
            className="w-8 h-8"
            onClick={onClickRemove}
          >
            <XIcon className="h-4 w-4 text-red-400" />
          </Button>
        )}

        {onClickAccept && (
          <Button
            variant="outline"
            size="icon"
            className="w-8 h-8"
            onClick={onClickAccept}
          >
            <CheckIcon className="h-4 w-4 text-green-400" />
          </Button>
        )}

        {onClickSend && (
          <Button
            variant="outline"
            size="icon"
            className="w-8 h-8"
            onClick={onClickSend}
          >
            <SendHorizonal className="h-4 w-4 text-blue-400" />
          </Button>
        )}
      </div>
    </div>
  );
};
