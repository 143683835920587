import { client } from "../client";

export type SendFriendRequestRequestDTO = {
  userId: string;
};

export const sendFriendRequest = async (
  params: SendFriendRequestRequestDTO
) => {
  const res = await client.post(`/friends/${params.userId}`);

  return res.data;
};
