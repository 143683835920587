import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Typography from "../components/typography";
import { CompanyTab } from "../components/ui/settings/company";
import { DangerZoneTab } from "../components/ui/settings/danger-zone";
import { IntegrationsTab } from "../components/ui/settings/integrations";
import { ProfileTab } from "../components/ui/settings/profile";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { Company, getCompany } from "../data/company/get-company";
import { useAuthStore } from "../stores/auth.store";

export const Settings = () => {
  const location = useLocation();

  const isCompanyAdmin = useAuthStore((store) => store.isCompanyAdmin);
  const companyId = useAuthStore((store) => store.companyId);

  const [company, setCompany] = useState<Company>();

  useEffect(() => {
    const loadCompanyData = async () => {
      const data = await getCompany({
        companyId,
      });

      setCompany(data);
    };

    if (companyId) {
      loadCompanyData();
    }
  }, [companyId]);

  return (
    <div className="p-4 md:p-6 pt-2 flex flex-col w-full h-full">
      <Typography variant="h2">Settings</Typography>

      <Tabs defaultValue={location.state?.defaultTab || "account"}>
        <TabsList className="flex items-center justify-start flex-wrap h-auto space-y-1">
          <TabsTrigger value="account">Account</TabsTrigger>
          <TabsTrigger value="integrations">Integrations</TabsTrigger>
          <TabsTrigger value="company" disabled={!companyId || !isCompanyAdmin}>
            Company
          </TabsTrigger>
          <TabsTrigger value="dangerzone">Danger Zone</TabsTrigger>
        </TabsList>

        <TabsContent value="account" className="pb-20">
          <ProfileTab teams={company?.teams || []} />
        </TabsContent>

        <TabsContent value="integrations">
          <IntegrationsTab />
        </TabsContent>

        <TabsContent value="company">
          <CompanyTab />
        </TabsContent>

        <TabsContent value="dangerzone">
          <DangerZoneTab />
        </TabsContent>
      </Tabs>
    </div>
  );
};
