import { client } from "../client";

type UpdateCompanyRequestDTO = {
  companyId: string;
  avatarFile?: File;
  backgroundId?: string;
};

export const updateCompany = async (params: UpdateCompanyRequestDTO) => {
  if (params.backgroundId) {
    await client.patch(`/companies/${params.companyId}`, {
      backgroundId: params.backgroundId,
    });
  }

  if (params.avatarFile) {
    const formData = new FormData();

    formData.append("avatar", params.avatarFile);

    await client.patch(`/companies/${params.companyId}/avatars`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
};
