import { client } from "../client";

export type TeamContribution = {
  teamCodeName: string;
  totalExp: number;
  bikingExp: number;
  runningExp: number;
  walkingExp: number;
  swimmingExp: number;
};

type GetContributionPerTeamRequestDTO = {
  companyId: string;
};
type GetContributionPerTeamResponseDTO = TeamContribution[];

export const getContributionPerTeam = async (
  props: GetContributionPerTeamRequestDTO
) => {
  const res = await client.get<GetContributionPerTeamResponseDTO>(
    `/companies/${props.companyId}/contributions`
  );

  return res.data;
};
