import { client } from "../client";

export type SportStats = {
  totalDuration: number;
  totalDistance: number;
};

export type XPBoostStats = {
  nbAvailable: number;
  nbForged: number;
  nbApplied: number;

  totalPercentAvailable: number;
  totalPercentUsed: number;
  totalXPGiven: number;
};

export type UserStats = {
  activeSinceDays: number;
  teamContribution: number;
  companyContribution: number;
  totalDuration: number;
  nbActivities: number;
  gridLevel: number;

  xpBoosts: XPBoostStats;

  biking: SportStats;
  running: SportStats;
  swimming: SportStats;
  walking: SportStats;
};

type GetUserStatsRequestDTO = {
  userId: string;
};
type GetUserStatsResponseDTO = UserStats;

export const getUserStats = async (params: GetUserStatsRequestDTO) => {
  const res = await client.get<GetUserStatsResponseDTO>(
    `/users/${params.userId}/stats`
  );

  return res.data;
};
