import { client } from "../client";

export type MinigameUpgrade = {
  id: string;
  minigameId: "GRID_REVEAL" | "XP_FORGE";
  upgradeType: string;
  currentLevel: number;
  currentLevelValue: number;
  nextLevelValue: number;
  nextLevelCost: number;
  maxLevel: number;
};

type GetMinigameUpgradesRequestDTO = {
  userId: string;
};

type GetMinigameUpgradesResponseDTO = {
  userSweatDrops: number;
  upgrades: MinigameUpgrade[];
};

export const getMinigameUpgrades = async (
  params: GetMinigameUpgradesRequestDTO
) => {
  const res = await client.get<GetMinigameUpgradesResponseDTO>(
    `/upgrades?userId=${params.userId}`
  );

  return res.data;
};
