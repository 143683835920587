import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useMediaQuery } from "@uidotdev/usehooks";
import dayjs from "dayjs";
import { ArrowLeft, X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { abandonActiveTrainingPlan } from "../../../data/training-plans/abandon-active-training-plan";
import { getUserTrainingPlans } from "../../../data/training-plans/get-training-plans";
import {
  paceConfigurations,
  trainingPlanNames,
} from "../../../data/training-plans/plans";
import { FullTrainingPlan } from "../../../data/training-plans/types";
import { formatDate } from "../../../lib/date-utils";
import { calculateTotalXPReward } from "../../../lib/training-plan-utils";
import { StartTrainingPlanDialog } from "../../dialogs/start-training-plan-dialog";
import Typography from "../../typography";
import { Button } from "../button";
import { ScrollArea, ScrollBar } from "../scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../tabs";
import { TrainingDayCard } from "./training-day-card";
import { TrainingOverviewCard } from "./training-overview-card";
import { WeekRectangleCard } from "./week-rectangle-card";

const scrollToItem = (
  container: HTMLElement | null,
  itemIndex: number,
  smooth = false
) => {
  if (container?.children.length) {
    const element = container?.children[itemIndex] as HTMLElement;

    element?.scrollIntoView({
      behavior: smooth ? "smooth" : "auto",
      block: "nearest",
      inline: "nearest",
    });
  }
};

export function TrainingPlanPage() {
  const navigate = useNavigate();

  const isSmallDevice = useMediaQuery("only screen and (max-width: 768px)");

  const scrollContainerRef = useRef(null);
  const scrollContainerRectRef = useRef(null);

  const [trainingPlans, setTrainingPlans] = useState<FullTrainingPlan[]>([]);
  const [selectedTrainingPlan, setSelectedTrainingPlan] =
    useState<FullTrainingPlan | null>();
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [selectedWeek, setSelectedWeek] = useState<number>(0);

  useEffect(() => {
    const getTrainingPlans = async () => {
      const data = await getUserTrainingPlans();

      if (data) {
        setTrainingPlans(data);

        // Check if there's an active TP. if so, set the current week as selected.
        if (data.length > 0 && data[0].currentWeek) {
          setSelectedTrainingPlan(data[0]);
          setSelectedWeek(data[0].currentWeek + 1);
        }
      }
    };

    getTrainingPlans();
  }, []);

  useEffect(() => {
    scrollToItem(scrollContainerRef.current, selectedWeek, true);
    scrollToItem(scrollContainerRectRef.current, selectedWeek, true);
  }, [selectedWeek, selectedTab]);

  const onClickCancel = async () => {
    const isConfirmed = window.confirm(
      "Are you sure you want to abandon your training plan?"
    );

    if (isConfirmed) {
      await abandonActiveTrainingPlan();

      navigate(0);
    }
  };

  if (!selectedTrainingPlan) {
    return (
      <div className="flex flex-col gap-2 w-full h-full justify-between items-center">
        <div className="flex flex-col gap-2 h-full w-full items-center justify-center content-center">
          <p>You're not following a training plan yet.</p>
          <StartTrainingPlanDialog />
        </div>

        <div className="w-full lg:w-2/3">
          <Table className="w-full">
            <TableCaption>Previous Training Plans</TableCaption>

            <TableHeader>
              <TableRow>
                <TableHead>Started</TableHead>
                <TableHead>Type</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>XP</TableHead>
                <TableHead>Link</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {trainingPlans.map((tp) => (
                <TableRow key={tp.id}>
                  <TableCell className="font-medium">
                    {formatDate(tp.createdAt, "DD/MM/YYYY")}
                  </TableCell>

                  <TableCell>{trainingPlanNames[tp.type]}</TableCell>

                  <TableCell>
                    {tp.isCompleted
                      ? "Completed"
                      : tp.isAbandonned
                      ? "Abandonned"
                      : tp.currentWeek
                      ? "Ongoing"
                      : "Incomplete"}
                  </TableCell>

                  <TableCell>{calculateTotalXPReward(tp)} XP</TableCell>

                  <TableCell onClick={() => setSelectedTrainingPlan(tp)}>
                    <Typography variant="p" affects="link" className="text-sm">
                      View
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }

  if (isSmallDevice) {
    return (
      <div className="flex flex-row gap-4 w-full h-full bg-white rounded-lg border border-slate-200 p-4">
        <Tabs
          defaultValue={
            selectedTrainingPlan.currentWeek !== undefined
              ? "planning"
              : "overview"
          }
          className="w-full h-full flex flex-col overflow-hidden"
          onValueChange={(value) => setSelectedTab(value)}
        >
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger
              value="planning"
              disabled={selectedTrainingPlan.currentWeek === undefined}
            >
              Planning
            </TabsTrigger>
            <TabsTrigger value="overview">Overview</TabsTrigger>
          </TabsList>

          <TabsContent
            value="planning"
            className="w-full h-[calc(100%-2.5rem)]"
          >
            <div className="w-full h-full overflow-hidden">
              <ScrollArea className="w-full">
                <div
                  className="flex flex-row gap-2 w-max my-2"
                  ref={scrollContainerRectRef}
                >
                  {selectedTrainingPlan.weeks.map((data, index) => (
                    <WeekRectangleCard
                      key={index}
                      weekNumber={index + 1}
                      weekEndDate={data.endDate}
                      objective={data.objective}
                      isSelected={selectedWeek === index}
                      isCurrent={selectedTrainingPlan.currentWeek === index}
                      onClick={() => setSelectedWeek(index)}
                    />
                  ))}
                </div>

                <ScrollBar orientation="horizontal" />
              </ScrollArea>

              <div className="w-full  h-[calc(100%-7.5rem)] overflow-hidden">
                <ScrollArea className="h-full">
                  <div className="flex flex-col gap-2 h-full">
                    {selectedTrainingPlan.days
                      .filter((day) => day.week === selectedWeek)
                      .sort((a, b) => {
                        if (a.week === b.week) {
                          return a.weekDay - b.weekDay;
                        }
                        return a.week - b.week;
                      })
                      .map((day) => (
                        <TrainingDayCard
                          key={day.week + day.weekDay}
                          date={dayjs(
                            selectedTrainingPlan.weeks.find(
                              (w) => w.week === day.week
                            )!.startDate
                          )
                            .set("day", day.weekDay)
                            .toISOString()}
                          objective={day.distance}
                          difficulty={day.difficulty}
                          zone={paceConfigurations[day.difficulty].zone}
                        />
                      ))}
                  </div>
                </ScrollArea>
              </div>
            </div>
          </TabsContent>

          <TabsContent value="overview" className="h-full overflow-hidden">
            <div className="w-full h-full overflow-hidden flex flex-col">
              <div className="flex w-full justify-between items-center">
                <Typography variant="h3">Overview</Typography>

                {selectedTrainingPlan.currentWeek !== undefined ? (
                  <Button
                    variant="destructive"
                    size="xs"
                    onClick={onClickCancel}
                  >
                    <X className="size-4" />
                    Cancel training
                  </Button>
                ) : (
                  <Button
                    variant="secondary"
                    size="xs"
                    onClick={() => setSelectedTrainingPlan(null)}
                  >
                    <ArrowLeft className="size-4" />
                    Back
                  </Button>
                )}
              </div>

              <div className="flex flex-col gap-1 justify-center items-center">
                <div className="w-full flex justify-center items-center">
                  <p className="text-blue-400 font-bold text-lg">
                    {selectedTrainingPlan.weeks.reduce(
                      (total, week) =>
                        (total +=
                          week.progress >= week.objective * 0.8
                            ? week.expReward
                            : 0),
                      0
                    )}{" "}
                    XP
                  </p>
                </div>

                <div className="w-full flex justify-center items-center">
                  <p className="text-sm">
                    {selectedTrainingPlan.currentWeek !== undefined
                      ? "Rewards are given once training is completed."
                      : "Rewards were given once training was completed."}
                  </p>
                </div>
              </div>

              <div className="w-full overflow-hidden">
                <ScrollArea className="h-full overflow-hidden">
                  <div
                    className="h-full flex flex-col gap-2 my-1 mx-1"
                    ref={scrollContainerRef}
                  >
                    {selectedTrainingPlan.weeks.map((data, index) => (
                      <TrainingOverviewCard
                        key={data.id}
                        week={data.week + 1}
                        weekEndDate={data.endDate}
                        progress={data.progress}
                        objective={data.objective}
                        reward={data.expReward}
                        isSelected={index === selectedWeek}
                        onClick={() => setSelectedWeek(index)}
                      />
                    ))}
                  </div>
                </ScrollArea>
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </div>
    );
  }

  return (
    <div className="flex flex-row gap-4 w-full h-full">
      <div className="w-full h-full p-4 max-w-4xl flex flex-col gap-4 bg-white rounded-lg border border-slate-200">
        <div className="flex w-full justify-center">
          <Typography variant="h3">
            {selectedTrainingPlan.currentWeek !== undefined
              ? "Active Training Plan"
              : "Previous Training Plan"}
          </Typography>
        </div>

        <div
          className={`w-max grid grid-cols-8 gap-2 ${
            selectedTrainingPlan.weeks.length > 8
              ? "grid-rows-2"
              : "grid-rows-1"
          }`}
        >
          {selectedTrainingPlan.weeks.map((data, index) => (
            <WeekRectangleCard
              key={index}
              weekNumber={data.week + 1}
              weekEndDate={data.endDate}
              objective={data.objective}
              isSelected={selectedWeek === index}
              isCurrent={selectedTrainingPlan.currentWeek === index}
              onClick={() => setSelectedWeek(index)}
            />
          ))}
        </div>

        <ScrollArea className="w-full h-full">
          <div className="flex flex-col gap-2 h-full">
            {selectedTrainingPlan.days
              .filter((day) => day.week === selectedWeek)
              .sort((a, b) => {
                if (a.week === b.week) {
                  return a.weekDay - b.weekDay;
                }
                return a.week - b.week;
              })
              .map((day) => (
                <TrainingDayCard
                  key={day.week + day.weekDay}
                  date={dayjs(
                    selectedTrainingPlan.weeks.find((w) => w.week === day.week)!
                      .startDate
                  )
                    .set("day", day.weekDay)
                    .toISOString()}
                  objective={day.distance}
                  difficulty={day.difficulty}
                  zone={paceConfigurations[day.difficulty].zone}
                />
              ))}
          </div>
        </ScrollArea>
      </div>

      <div className="h-full w-full bg-white rounded-lg border border-slate-200 p-4 flex flex-col gap-2">
        <div className="flex w-full justify-between items-center">
          <Typography variant="h3">Overview</Typography>

          {selectedTrainingPlan.currentWeek !== undefined ? (
            <Button variant="destructive" size="xs" onClick={onClickCancel}>
              <X className="size-4" />
              Cancel training
            </Button>
          ) : (
            <Button
              variant="secondary"
              size="xs"
              onClick={() => setSelectedTrainingPlan(null)}
            >
              <ArrowLeft className="size-4" />
              Back
            </Button>
          )}
        </div>

        <div className="flex flex-col gap-1 justify-center items-center">
          <div className="w-full flex justify-center items-center">
            <p className="text-blue-400 font-bold text-lg">
              {selectedTrainingPlan.weeks.reduce(
                (total, week) =>
                  (total +=
                    week.progress >= week.objective * 0.8 ? week.expReward : 0),
                0
              )}{" "}
              XP
            </p>
          </div>

          <div className="w-full flex justify-center items-center">
            <p className="text-sm">
              {selectedTrainingPlan.currentWeek !== undefined
                ? "Rewards are given once training is completed."
                : "Rewards were given once training was completed."}{" "}
            </p>
          </div>
        </div>

        <div className="h-full overflow-hidden">
          <ScrollArea className="h-full">
            <div
              className="h-full flex flex-col gap-2 m-2"
              ref={scrollContainerRef}
            >
              {selectedTrainingPlan.weeks.map((week, index) => (
                <TrainingOverviewCard
                  key={week.endDate}
                  week={index + 1}
                  weekEndDate={week.endDate}
                  progress={week.progress}
                  objective={week.objective}
                  reward={week.expReward}
                  isSelected={index === selectedWeek}
                  onClick={() => setSelectedWeek(index)}
                />
              ))}
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
}
