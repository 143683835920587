import { client } from "../client";

type CreateTeamRequestDTO = {
  companyId: string;
  name: string;
  codeName: string;
};

export const createTeam = async (params: CreateTeamRequestDTO) => {
  await client.post(`/companies/${params.companyId}/teams`, {
    name: params.name,
    codeName: params.codeName,
  });
};
