import { client } from "../client";

type CreateCompanyRequestDTO = {
  name: string;
  iconFile?: File;
};
type CreateCompanyResponseDTO = {
  id: string;
  name: string;
  level: number;
};

export const createCompany = async (params: CreateCompanyRequestDTO) => {
  const res = await client.post<CreateCompanyResponseDTO>("/companies", {
    name: params.name,
  });
  const data = res.data;

  if (params.iconFile) {
    const formData = new FormData();

    formData.append("avatar", params.iconFile);

    await client.patch(`/companies/${data.id}/avatars`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  return data;
};
