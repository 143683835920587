import { InfoCircledIcon } from "@radix-ui/react-icons";
import { PlusIcon } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";
import { createTeam } from "../../../data/company/create-team";
import { Team } from "../../../data/company/get-company";
import { useAuthStore } from "../../../stores/auth.store";
import Typography from "../../typography";
import { Button } from "../button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../dialog";
import { Input } from "../input";
import { Label } from "../label";
import { ScrollArea, ScrollBar } from "../scroll-area";
import { Tooltip, TooltipContent, TooltipTrigger } from "../tooltip";
import { TeamItem } from "./team-item";

type TeamsProps = { teams: Team[]; refreshCompanyData: () => void };

export const Teams = (props: TeamsProps) => {
  const [teamName, setTeamName] = useState("");
  const [teamCodeName, setTeamCodeName] = useState("");

  const companyId = useAuthStore((store) => store.companyId);
  const isCompanyAdmin = useAuthStore((store) => store.isCompanyAdmin);

  const onClickCreateTeam = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const trimmedTeamName = teamName.trim();
    const trimmedCodeName = teamCodeName.trim().toUpperCase();

    if (!trimmedTeamName || !trimmedCodeName) {
      toast("An error occured", {
        description: "Both team name and team code name are required.",
      });

      event.preventDefault();

      return;
    }

    if (trimmedCodeName.length > 5) {
      toast("An error occured", {
        description: "Team codename must not exceed 5 characters.",
      });

      event.preventDefault();

      return;
    }

    await createTeam({
      companyId,
      name: teamName,
      codeName: teamCodeName,
    });

    props.refreshCompanyData();
  };

  return (
    <div className="flex flex-col gap-3 w-full bg-white rounded-lg border border-slate-200 p-4 mt-4">
      {/* Title (Teams + num) + create button */}
      <div className="flex flex-row w-full justify-between">
        <div className="flex flex-row items-end gap-3">
          <Typography variant="h3">Teams</Typography>
          <Typography variant="h3" affects="light" className="text-blue-500">
            {props.teams.length}
          </Typography>
        </div>

        <Dialog>
          <DialogTrigger asChild>
            <Button
              variant="outline"
              size="icon"
              className="rounded-full"
              disabled={!isCompanyAdmin}
            >
              <PlusIcon className="h-4 w-4" />
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-md">
            <DialogHeader>
              <DialogTitle>Team creation</DialogTitle>
              <DialogDescription>
                Company members will be able to join this team.
              </DialogDescription>
            </DialogHeader>

            <div className="flex flex-col space-y-4">
              <div className="grid gap-2">
                <Label htmlFor="teamName">Team name</Label>
                <Input
                  id="teamName"
                  placeholder="Technical"
                  value={teamName}
                  onChange={(event) => setTeamName(event.target.value)}
                />
              </div>

              <div className="grid gap-2">
                <div className="flex flex-row items-center gap-2">
                  <Label htmlFor="teamCodename">Team short name</Label>
                  <Tooltip>
                    <TooltipTrigger>
                      <InfoCircledIcon className="w-4 h-4" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        A short 'code name' for the team, to display in charts.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </div>
                <Input
                  id="teamCodename"
                  placeholder="TECH"
                  value={teamCodeName}
                  className="uppercase"
                  maxLength={5}
                  onChange={(event) =>
                    setTeamCodeName(event.target.value.toUpperCase())
                  }
                />
              </div>
            </div>

            <DialogFooter className="mt-6">
              <DialogClose asChild>
                <Button
                  type="button"
                  variant="default"
                  onClick={(event) => onClickCreateTeam(event)}
                  className="w-full"
                >
                  Create
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>

      {/* List of teams */}
      <ScrollArea className="h-64">
        <ScrollBar orientation="vertical" />

        <div className="flex flex-col gap-2 pl-2">
          {props.teams.map((team) => (
            <TeamItem
              key={team.id}
              id={team.id}
              name={team.name}
              shortName={team.codeName}
              members={team.members}
              refreshCompanyData={props.refreshCompanyData}
            />
          ))}
        </div>
      </ScrollArea>
    </div>
  );
};
