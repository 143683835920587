import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuthStore } from "../stores/auth.store";

const AuthHandleRoute = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();

  const isAuthenticated = useAuthStore((store) => !!store.accessToken);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (location.pathname === "/") {
    return <Navigate to="/home" />;
  }

  return children;
};

export default AuthHandleRoute;
