import { XPBoost } from "../../../data/activities/get-xp-boosts";
import { formatDate } from "../../../lib/date-utils";
import { getDisplayName } from "../../../lib/minigame-utils";
import { Card, CardContent } from "../card";

type BoostCardProps = {
  boost: XPBoost;
  isSelected: boolean;
  onClick: () => void;
};

export const BoostCard = ({ boost, isSelected, onClick }: BoostCardProps) => {
  return (
    <Card
      className={`cursor-pointer transition-all ${
        isSelected ? "bg-gray-200" : "hover:bg-accent"
      }`}
      onClick={onClick}
    >
      <CardContent className="p-4 flex items-center justify-between">
        <div>
          <p className="text-lg font-bold text-blue-400">{boost.value}%</p>
          <p className="text-sm text-muted-foreground">
            {formatDate(boost.createdAt)} • {getDisplayName(boost.source)}
          </p>
        </div>
      </CardContent>
    </Card>
  );
};
