import {
  PaceConfigurations,
  TrainingPlanDays,
  TrainingPlanType,
} from "./types";

export const paceConfigurations: PaceConfigurations = {
  BEST_PACE: {
    zone: 5,
    description: "Push your limits at your fastest sustainable pace.",
  },
  MILE_PACE: {
    zone: 4,
    description: "This is the pace you could race for one mile.",
  },
  TEMPO_PACE: {
    zone: 3,
    description: "Sustained effort at a challenging but manageable pace.",
  },
  LONG_RUN_PACE: {
    zone: 2,
    description: "This is the pace you could race for about 10 km.",
  },
  COMFORTABLE_PACE: {
    zone: 1,
    description:
      "Teaching your body to be comfortable with being uncomfortable.",
  },
  RECOVERY_PACE: {
    zone: 1,
    description: "Easy run at a comfortable, conversational pace.",
  },
  RACE_DAY: {
    zone: 4,
    description:
      "Give it your best effort based on your training and conditions.",
  },
};

export const trainingPlanDays: TrainingPlanDays = {
  RUN_5K: [
    { week: 0, weekDay: 1, distance: 1, difficulty: "COMFORTABLE_PACE" },
    { week: 0, weekDay: 3, distance: 1, difficulty: "COMFORTABLE_PACE" },
    { week: 0, weekDay: 7, distance: 2, difficulty: "LONG_RUN_PACE" },

    { week: 1, weekDay: 1, distance: 1.5, difficulty: "COMFORTABLE_PACE" },
    { week: 1, weekDay: 3, distance: 1.5, difficulty: "COMFORTABLE_PACE" },
    { week: 1, weekDay: 7, distance: 2, difficulty: "LONG_RUN_PACE" },

    { week: 2, weekDay: 1, distance: 2, difficulty: "COMFORTABLE_PACE" },
    { week: 2, weekDay: 3, distance: 2, difficulty: "TEMPO_PACE" },
    { week: 2, weekDay: 7, distance: 2.5, difficulty: "LONG_RUN_PACE" },

    { week: 3, weekDay: 1, distance: 2.5, difficulty: "COMFORTABLE_PACE" },
    { week: 3, weekDay: 3, distance: 2.5, difficulty: "TEMPO_PACE" },
    { week: 3, weekDay: 7, distance: 3, difficulty: "LONG_RUN_PACE" },

    { week: 4, weekDay: 1, distance: 2, difficulty: "COMFORTABLE_PACE" },
    { week: 4, weekDay: 3, distance: 2, difficulty: "TEMPO_PACE" },
    { week: 4, weekDay: 7, distance: 3.5, difficulty: "LONG_RUN_PACE" },

    { week: 5, weekDay: 1, distance: 2.5, difficulty: "COMFORTABLE_PACE" },
    { week: 5, weekDay: 3, distance: 2.5, difficulty: "TEMPO_PACE" },
    { week: 5, weekDay: 7, distance: 4, difficulty: "LONG_RUN_PACE" },

    { week: 6, weekDay: 1, distance: 2, difficulty: "COMFORTABLE_PACE" },
    { week: 6, weekDay: 3, distance: 2, difficulty: "TEMPO_PACE" },
    { week: 6, weekDay: 7, distance: 4, difficulty: "LONG_RUN_PACE" },

    { week: 7, weekDay: 1, distance: 1.5, difficulty: "COMFORTABLE_PACE" },
    { week: 7, weekDay: 3, distance: 2, difficulty: "TEMPO_PACE" },
    { week: 7, weekDay: 7, distance: 5, difficulty: "TEMPO_PACE" },
  ],

  RUN_10K: [
    { week: 0, weekDay: 1, distance: 3, difficulty: "COMFORTABLE_PACE" },
    { week: 0, weekDay: 3, distance: 3, difficulty: "COMFORTABLE_PACE" },
    { week: 0, weekDay: 7, distance: 4, difficulty: "LONG_RUN_PACE" },

    { week: 1, weekDay: 1, distance: 4, difficulty: "COMFORTABLE_PACE" },
    { week: 1, weekDay: 3, distance: 4, difficulty: "COMFORTABLE_PACE" },
    { week: 1, weekDay: 7, distance: 5, difficulty: "LONG_RUN_PACE" },

    { week: 2, weekDay: 1, distance: 4, difficulty: "COMFORTABLE_PACE" },
    { week: 2, weekDay: 3, distance: 5, difficulty: "COMFORTABLE_PACE" },
    { week: 2, weekDay: 7, distance: 5, difficulty: "LONG_RUN_PACE" },

    { week: 3, weekDay: 1, distance: 5, difficulty: "COMFORTABLE_PACE" },
    { week: 3, weekDay: 3, distance: 5, difficulty: "TEMPO_PACE" },
    { week: 3, weekDay: 7, distance: 7, difficulty: "LONG_RUN_PACE" },

    { week: 4, weekDay: 1, distance: 6, difficulty: "COMFORTABLE_PACE" },
    { week: 4, weekDay: 3, distance: 6, difficulty: "TEMPO_PACE" },
    { week: 4, weekDay: 7, distance: 8, difficulty: "LONG_RUN_PACE" },

    { week: 5, weekDay: 1, distance: 4, difficulty: "COMFORTABLE_PACE" },
    { week: 5, weekDay: 3, distance: 5, difficulty: "TEMPO_PACE" },
    { week: 5, weekDay: 7, distance: 9, difficulty: "LONG_RUN_PACE" },

    { week: 6, weekDay: 1, distance: 6, difficulty: "COMFORTABLE_PACE" },
    { week: 6, weekDay: 3, distance: 6, difficulty: "TEMPO_PACE" },
    { week: 6, weekDay: 7, distance: 5, difficulty: "LONG_RUN_PACE" },

    { week: 7, weekDay: 1, distance: 3, difficulty: "COMFORTABLE_PACE" },
    { week: 7, weekDay: 3, distance: 2, difficulty: "TEMPO_PACE" },
    { week: 7, weekDay: 7, distance: 10, difficulty: "TEMPO_PACE" },
  ],

  RUN_HALFMARATHON: [
    { week: 0, weekDay: 2, distance: 5, difficulty: "COMFORTABLE_PACE" },
    { week: 0, weekDay: 4, distance: 6, difficulty: "COMFORTABLE_PACE" },
    { week: 0, weekDay: 6, distance: 6, difficulty: "COMFORTABLE_PACE" },
    { week: 0, weekDay: 7, distance: 8, difficulty: "LONG_RUN_PACE" },

    { week: 1, weekDay: 2, distance: 6, difficulty: "COMFORTABLE_PACE" },
    { week: 1, weekDay: 3, distance: 8, difficulty: "TEMPO_PACE" },
    { week: 1, weekDay: 6, distance: 6, difficulty: "COMFORTABLE_PACE" },
    { week: 1, weekDay: 7, distance: 10, difficulty: "LONG_RUN_PACE" },

    { week: 2, weekDay: 2, distance: 9, difficulty: "COMFORTABLE_PACE" },
    { week: 2, weekDay: 4, distance: 9, difficulty: "MILE_PACE" },
    { week: 2, weekDay: 6, distance: 6, difficulty: "COMFORTABLE_PACE" },
    { week: 2, weekDay: 7, distance: 12, difficulty: "LONG_RUN_PACE" },

    { week: 3, weekDay: 2, distance: 8, difficulty: "COMFORTABLE_PACE" },
    { week: 3, weekDay: 4, distance: 10, difficulty: "TEMPO_PACE" },
    { week: 3, weekDay: 6, distance: 8, difficulty: "COMFORTABLE_PACE" },
    { week: 3, weekDay: 7, distance: 12, difficulty: "LONG_RUN_PACE" },

    { week: 4, weekDay: 2, distance: 8, difficulty: "COMFORTABLE_PACE" },
    { week: 4, weekDay: 4, distance: 8, difficulty: "MILE_PACE" },
    { week: 4, weekDay: 6, distance: 6, difficulty: "COMFORTABLE_PACE" },
    { week: 4, weekDay: 7, distance: 10, difficulty: "LONG_RUN_PACE" },

    { week: 5, weekDay: 2, distance: 8, difficulty: "COMFORTABLE_PACE" },
    { week: 5, weekDay: 3, distance: 5, difficulty: "COMFORTABLE_PACE" },
    { week: 5, weekDay: 4, distance: 12, difficulty: "TEMPO_PACE" },
    { week: 5, weekDay: 6, distance: 8, difficulty: "COMFORTABLE_PACE" },
    { week: 5, weekDay: 7, distance: 15, difficulty: "LONG_RUN_PACE" },

    { week: 6, weekDay: 2, distance: 11, difficulty: "COMFORTABLE_PACE" },
    { week: 6, weekDay: 3, distance: 6, difficulty: "COMFORTABLE_PACE" },
    { week: 6, weekDay: 4, distance: 12, difficulty: "COMFORTABLE_PACE" },
    { week: 6, weekDay: 6, distance: 8, difficulty: "COMFORTABLE_PACE" },
    { week: 6, weekDay: 7, distance: 16, difficulty: "LONG_RUN_PACE" },

    { week: 7, weekDay: 2, distance: 10, difficulty: "COMFORTABLE_PACE" },
    { week: 7, weekDay: 3, distance: 6, difficulty: "COMFORTABLE_PACE" },
    { week: 7, weekDay: 4, distance: 14, difficulty: "TEMPO_PACE" },
    { week: 7, weekDay: 6, distance: 8, difficulty: "COMFORTABLE_PACE" },
    { week: 7, weekDay: 7, distance: 18, difficulty: "LONG_RUN_PACE" },

    { week: 8, weekDay: 2, distance: 12, difficulty: "COMFORTABLE_PACE" },
    { week: 8, weekDay: 3, distance: 8, difficulty: "COMFORTABLE_PACE" },
    { week: 8, weekDay: 4, distance: 14, difficulty: "MILE_PACE" },
    { week: 8, weekDay: 6, distance: 8, difficulty: "COMFORTABLE_PACE" },
    { week: 8, weekDay: 7, distance: 18, difficulty: "LONG_RUN_PACE" },

    { week: 9, weekDay: 2, distance: 14, difficulty: "COMFORTABLE_PACE" },
    { week: 9, weekDay: 3, distance: 6, difficulty: "COMFORTABLE_PACE" },
    { week: 9, weekDay: 4, distance: 14, difficulty: "TEMPO_PACE" },
    { week: 9, weekDay: 6, distance: 8, difficulty: "COMFORTABLE_PACE" },
    { week: 9, weekDay: 7, distance: 21, difficulty: "LONG_RUN_PACE" },

    { week: 10, weekDay: 2, distance: 10, difficulty: "COMFORTABLE_PACE" },
    { week: 10, weekDay: 4, distance: 10, difficulty: "MILE_PACE" },
    { week: 10, weekDay: 6, distance: 5, difficulty: "COMFORTABLE_PACE" },
    { week: 10, weekDay: 7, distance: 15, difficulty: "LONG_RUN_PACE" },

    { week: 11, weekDay: 2, distance: 8, difficulty: "COMFORTABLE_PACE" },
    { week: 11, weekDay: 3, distance: 8, difficulty: "MILE_PACE" },
    { week: 11, weekDay: 4, distance: 5, difficulty: "COMFORTABLE_PACE" },
    { week: 11, weekDay: 7, distance: 21.1, difficulty: "LONG_RUN_PACE" },
  ],

  RUN_MARATHON: [],
};

export const trainingPlanNames: Record<TrainingPlanType, string> = {
  RUN_5K: "Run 5 km",
  RUN_10K: "Run 10 km",
  RUN_HALFMARATHON: "Run a Half Marathon",
  RUN_MARATHON: "Run a Marathon",
};
