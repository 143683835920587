import { Button } from "@/components/ui/button";
import dayjs from "dayjs";

import { ChevronLeft, ChevronRight } from "lucide-react";
import React from "react";

type MonthSelectorProps = {
  currentDate: Date;
  maxDate: Date;
  onDateChange: (newDate: Date) => void;
};

const MonthSelector: React.FC<MonthSelectorProps> = ({
  currentDate,
  maxDate,
  onDateChange,
}) => {
  const handlePreviousMonth = () => {
    const newDate = dayjs(currentDate).subtract(1, "month").toDate();
    onDateChange(newDate);
  };

  const handleNextMonth = () => {
    const newDate = dayjs(currentDate).add(1, "month").toDate();
    onDateChange(newDate);
  };

  return (
    <div className="w-full flex items-center justify-between space-x-4">
      <Button
        variant="outline"
        size="icon"
        onClick={handlePreviousMonth}
        aria-label="Previous month"
        className="w-6 h-6"
      >
        <ChevronLeft className="h-4 w-4" />
      </Button>

      <div className="text-sm font-medium">
        {dayjs(currentDate).format("MMMM YYYY")}
      </div>

      <Button
        variant="outline"
        size="icon"
        onClick={handleNextMonth}
        aria-label="Next month"
        className="w-6 h-6"
        disabled={dayjs(currentDate).add(1, "month").toDate() >= maxDate}
      >
        <ChevronRight className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default MonthSelector;
