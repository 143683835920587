import { client } from "../client";

type GetUserIntegrationsRequestDTO = {
  userId: string;
};

type GetUserIntegrationsResponseDTO = {
  stravaId: string;
};

export const getUserIntegrations = async (
  params: GetUserIntegrationsRequestDTO
) => {
  const res = await client.get<GetUserIntegrationsResponseDTO>(
    `/users/${params.userId}/integrations`
  );

  return res.data;
};
