import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { GameGrid } from "../components/ui/grid-reveal/gr-game-grid";
import { InfoSection } from "../components/ui/grid-reveal/gr-info-section";
import { UpgradesSection } from "../components/ui/grid-reveal/gr-upgrades-section";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { claimCell } from "../data/minigames/grid-reveal/claim-cell";
import { createGrid } from "../data/minigames/grid-reveal/create-grid";
import {
  getGridById,
  Grid,
} from "../data/minigames/grid-reveal/get-grid-by-id";
import { getGrids } from "../data/minigames/grid-reveal/get-grids";
import { useAuthStore } from "../stores/auth.store";

export function GridReveal() {
  const params = useParams();

  const loggedInUserId = useAuthStore((store) => store.userId);

  const userId = params?.userId || loggedInUserId;
  const isSameUser = userId === loggedInUserId;

  const [grid, setGrid] = useState<Grid | null>(null);
  const [highlightedCell, setHighlightedCell] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const fetchGrid = async (gridId: string) => {
    const grid = await getGridById({ id: gridId });
    setGrid(grid);
  };

  useEffect(() => {
    const fetchInitialGrid = async () => {
      const gridOverviews = await getGrids({ userId });

      if (gridOverviews.length > 0) {
        const lastGrid = gridOverviews[gridOverviews.length - 1];
        await fetchGrid(lastGrid.id);
      } else {
        toast("Could not load grid 😢", {
          description: "User has not started a Grid Reveal game yet.",
          duration: 5000,
        });
      }
    };

    fetchInitialGrid();
  }, [userId, isSameUser]);

  const handleCellClick = async (x: number, y: number) => {
    const reward = await claimCell({ gridId: grid!.id, x, y });

    const energyReward = reward.onClaimEnergyReward;
    if (energyReward > 0) {
      toast(`Claimed cell and got ${energyReward}⚡ back!`, {
        duration: 5000,
        closeButton: true,
      });
    } else {
      toast(
        `Claimed cell and got a ${reward.onClaimExpBoostReward}% XP Boost 🥳`,
        {
          duration: 5000,
          closeButton: true,
        }
      );
    }

    // Note: must refresh whole grid to update stats, as well as impacted cells.
    await fetchGrid(grid!.id);
  };

  const handlePreviousLevel = async () => {
    if (grid?.previousGridId) {
      await fetchGrid(grid.previousGridId);
    }
  };

  const handleNextLevel = async () => {
    if (grid?.nextGridId) {
      await fetchGrid(grid.nextGridId);
    } else if (grid?.isFinished) {
      try {
        const createdGrid = await createGrid();
        await fetchGrid(createdGrid.id);
      } catch (error) {
        console.error("Error creating new grid:", error);
      }
    }
  };

  return (
    <div className="w-full h-full">
      {/* Desktop layout */}
      <div className="hidden md:flex w-full h-full">
        <div className="w-1/3 mr-4 h-full">
          <InfoSection grid={grid} setHighlightedCell={setHighlightedCell} />
        </div>

        <div className="w-1/3 bg-white border border-gray-200 rounded-md mr-4">
          {grid ? (
            <GameGrid
              grid={grid}
              onCellClick={handleCellClick}
              onPreviousLevel={handlePreviousLevel}
              onNextLevel={handleNextLevel}
              highlightedCell={highlightedCell}
            />
          ) : (
            <div className="flex justify-center items-center h-full">
              Loading...
            </div>
          )}
        </div>

        <div className="w-1/3">
          <UpgradesSection userId={userId} canBuyUpgrade={isSameUser} />
        </div>
      </div>

      {/* Mobile layout */}
      <div className="md:hidden w-full h-full">
        <Tabs defaultValue="info" className="w-full h-full">
          <TabsList className="w-full">
            <TabsTrigger value="info" className="w-1/3">
              Info
            </TabsTrigger>
            <TabsTrigger value="grid" className="w-1/3">
              Grid
            </TabsTrigger>
            <TabsTrigger value="upgrades" className="w-1/3">
              Upgrades
            </TabsTrigger>
          </TabsList>

          <TabsContent value="info">
            <InfoSection grid={grid} setHighlightedCell={setHighlightedCell} />
          </TabsContent>

          <TabsContent value="grid">
            <div className="bg-white border border-gray-200 rounded-md">
              {grid ? (
                <GameGrid
                  grid={grid}
                  onCellClick={handleCellClick}
                  onPreviousLevel={handlePreviousLevel}
                  onNextLevel={handleNextLevel}
                  highlightedCell={highlightedCell}
                />
              ) : (
                <div className="flex justify-center items-center h-64">
                  Loading...
                </div>
              )}
            </div>
          </TabsContent>

          <TabsContent value="upgrades">
            <UpgradesSection userId={userId} canBuyUpgrade={isSameUser} />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
