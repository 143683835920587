import { useEffect } from "react";
import { toast } from "sonner";
import Typography from "../components/typography";
import { linkStrava } from "../data/auth/link-strava";

export const StravaLinking = () => {
  const linkStravaAccount = async () => {
    const code = new URLSearchParams(window.location.search).get("code");

    if (!code) {
      toast.error("No code found!");
      return;
    }

    await linkStrava({ code });

    toast.success("Successfully linked your Strava account!");

    setTimeout(() => {
      window.location.href = "/home";
    }, 2000);
  };

  useEffect(() => {
    linkStravaAccount();
  }, []);

  return (
    <div className="flex flex-col justify-center items-center p-6 gap-1">
      <Typography>Linking your Strava account...</Typography>
    </div>
  );
};
