import { client } from "../client";

type UserTeam = {
  id: string;
  name: string;
  codeName: string;
};

export type ProfileBackground = {
  id: string;
  type: "solid" | "gradient" | "image";
  style: string;
};

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  experience: number;
  level: number;
  companyId: string;
  companyLevel: number;
  team?: UserTeam;
  avatarUrl: string;
  profileBackground?: ProfileBackground;

  // Only provided if req user === searched user
  stravaUserId?: string;
  isCompanyAdmin?: boolean;
  isEmailSubscribed?: boolean;
  isVerified?: boolean;
  email?: string;
};

type GetUserByIdRequestDTO = {
  userId: string;
};
type GetUserByIdResponseDTO = User;

export const getUserById = async (params: GetUserByIdRequestDTO) => {
  const res = await client.get<GetUserByIdResponseDTO>(
    `/users/${params.userId}`
  );

  return res.data;
};
