import { useMediaQuery } from "@uidotdev/usehooks";
import dayjs from "dayjs";
import { paceConfigurations } from "../../../data/training-plans/plans";
import { Difficulty } from "../../../data/training-plans/types";
import { Card, CardContent } from "../card";
import { HeartRateZoneChart } from "./heart-rate-zones";

type TrainingDayCardProps = {
  date: string;
  objective: number;
  difficulty: string;
  zone: number;
  isToday?: boolean;
};

export function TrainingDayCard({
  date,
  objective,
  difficulty,
  zone,
}: TrainingDayCardProps) {
  const isSmallDevice = useMediaQuery("only screen and (max-width: 768px)");

  const today = new Date();
  const itemDate = dayjs(date);
  const isToday = itemDate.isSame(today, "day");

  return (
    <Card className="w-full rounded-md">
      <CardContent className="p-0 flex flex-col gap-2">
        <div
          className={`flex flex-row gap-4 ${
            isSmallDevice ? "justify-center" : ""
          }`}
        >
          <div className="flex flex-col items-center justify-center pl-4 py-4 w-24">
            <div
              className={`text-sm font-medium ${
                isToday ? "text-blue-400" : "text-gray-500"
              }`}
            >
              {itemDate.format("ddd")}
            </div>

            <div
              className={`text-3xl font-bold ${
                isToday ? "text-blue-400" : "text-gray-700"
              }`}
            >
              {itemDate.format("DD")}
            </div>
          </div>

          <div className="w-px bg-gray-200 my-4" />

          <div className="flex flex-col items-center justify-center p-4 w-24">
            <div className="text-sm font-medium text-gray-500">Distance</div>

            <div className="flex flex-row gap-1 items-baseline">
              <span className="text-3xl font-bold text-gray-700">
                {objective}
              </span>

              <span className="text-xl font-bold text-gray-400">KM</span>
            </div>
          </div>

          {!isSmallDevice && <div className="w-px bg-gray-200 my-4" />}

          <div
            className={`flex flex-col items-center justify-center p-4 ${
              isSmallDevice && "hidden"
            }`}
          >
            <div className="text-sm font-medium text-gray-500">
              Heart Rate Zone
            </div>

            <HeartRateZoneChart
              selectedZone={zone}
              zoneTooltip={
                paceConfigurations[difficulty as Difficulty].description
              }
            />
          </div>
        </div>

        {isSmallDevice && (
          <div className="flex flex-col items-center justify-center px-4 pt-0 pb-4 w-full">
            <div className="text-sm font-medium text-gray-500">
              Heart Rate Zone
            </div>

            <HeartRateZoneChart
              selectedZone={zone}
              zoneTooltip={
                paceConfigurations[difficulty as Difficulty].description
              }
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
}
