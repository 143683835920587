import { client } from "../client";

export type HandleFriendRequestRequestDTO = {
  friendRequestId: string;
  accepted: boolean;
};

export const handleFriendRequest = async (
  params: HandleFriendRequestRequestDTO
) => {
  await client.patch(`/friends/requests/${params.friendRequestId}`, {
    accepted: params.accepted,
  });
};
