import { client } from "../client";
import { trainingPlanDays } from "./plans";
import { FullTrainingPlan, TrainingPlan } from "./types";

type GetTrainingPlansResponseDTO = TrainingPlan[];

export const getUserTrainingPlans = async (): Promise<FullTrainingPlan[]> => {
  const res = await client.get<GetTrainingPlansResponseDTO>("/training-plans");

  const trainingPlans = res.data;

  if (!trainingPlans) {
    return [];
  }

  return trainingPlans.map((tp) => ({
    ...tp,
    days: trainingPlanDays[tp.type],
  }));
};
