import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import Typography from "../components/typography";

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  const errorMessage = isRouteErrorResponse(error)
    ? error.statusText
    : error instanceof Error
    ? error.message
    : "Unexpected Error";

  return (
    <div className="w-screen h-full flex flex-col text-center justify-center">
      <Typography variant="h1">Oops!</Typography>
      <Typography variant="p" affects="small">
        {errorMessage}
      </Typography>
    </div>
  );
};
