import { Building2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { leaveCompany } from "../../data/company/leave-company";
import { useAuthStore } from "../../stores/auth.store";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";

export const LeaveCompanyDialog = () => {
  const navigate = useNavigate();

  const userId = useAuthStore((store) => store.userId);
  const setCompanyId = useAuthStore((store) => store.setCompanyId);

  const onClickLeaveCompany = async () => {
    await leaveCompany({
      userId,
    });

    setCompanyId("", false);

    navigate("/company");
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="destructive" className="w-min rounded-md">
          <Building2 className="size-5 accent-red-400 mr-2" />
          Leave company
        </Button>
      </DialogTrigger>
      <DialogContent className="w-[90vw] max-w-md mx-auto">
        <DialogHeader>
          <DialogTitle>Leaving company</DialogTitle>
          <DialogDescription>
            Are you sure you want to leave this company?
            <br />
            You'll be able to join again with the company's join code.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="sm:flex-row flex-col mt-4">
          <DialogClose asChild>
            <Button
              variant="secondary"
              className="sm:mr-2 mb-2 sm:mb-0 w-full sm:w-auto"
            >
              Cancel
            </Button>
          </DialogClose>
          <Button
            variant="destructive"
            onClick={onClickLeaveCompany}
            className="w-full sm:w-auto"
          >
            Leave
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
