import React from "react";
import { useNavigate } from "react-router-dom";
import {
  LeaderboardTeam,
  LeaderboardUser,
} from "../../../data/home/get-leaderboard";
import { getDisplayName } from "../../../lib/name-utils";
import { cn } from "../../../lib/utils";
import Typography from "../../typography";
import { Avatar, AvatarImage } from "../avatar";

type LeaderboardItemProps = {
  idx: number;
  item: LeaderboardUser | LeaderboardTeam;
  classname?: string;
};

export const LeaderboardItem: React.FC<LeaderboardItemProps> = ({
  idx,
  item,
  classname,
}) => {
  const navigate = useNavigate();
  const isUser = item && "userId" in item;

  const handleClick = () => {
    if (isUser) {
      navigate(`/profile/${item.userId}`);
    }
  };

  return (
    <div
      className={cn(
        "flex flex-row gap-3 border rounded-lg border-gray-200 p-2 items-center w-full",
        classname
      )}
    >
      <Typography variant="p" affects="light" className="text-sm w-6">
        {idx.toString().padStart(2, "0")}
      </Typography>

      <div className="flex flex-row gap-2 w-full items-center">
        {isUser && item.avatarUrl && (
          <Avatar
            affects="noborder"
            size="xs"
            className="hover:cursor-pointer"
            onClick={handleClick}
          >
            <AvatarImage src={item.avatarUrl} />
          </Avatar>
        )}

        <div className="flex flex-row w-full min-w-44 items-center justify-between gap-2">
          <div>
            <Typography
              variant="p"
              affects={isUser ? "link" : undefined}
              className="text-sm truncate max-w-32"
              onClick={handleClick}
            >
              {isUser
                ? `${getDisplayName(item.firstName, item.lastName)}`
                : item.teamCodeName}
            </Typography>

            {isUser && (
              <Typography
                variant="p"
                affects="muted"
                className="text-xs mt-[-4px]"
              >
                {item.teamCodeName}
              </Typography>
            )}
          </div>

          <Typography variant="p" className="text-xs text-primary">
            {item.experience} XP
          </Typography>
        </div>
      </div>
    </div>
  );
};
