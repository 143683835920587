import { API_BASE_URL, client } from "../client";

export type LeaderboardUser = {
  userId: string;
  firstName: string;
  lastName: string;
  experience: number;
  teamId: string;
  teamCodeName: string;
  avatarUrl: string;
};

export type LeaderboardTeam = {
  teamId: string;
  teamCodeName: string;
  experience: number;
};

type GetLeaderboardRequestDTO = {
  type: "COMPANY" | "TEAMS" | "FRIENDS";
  timeStart: Date;
  timeEnd: Date;
};
type GetLeaderboardResponseDTO = LeaderboardUser[] | LeaderboardTeam[];

export const getLeaderboard = async (params: GetLeaderboardRequestDTO) => {
  const url = new URL("/leaderboards", API_BASE_URL);

  url.searchParams.set("type", params.type);
  url.searchParams.set("timeStart", params.timeStart.toISOString());
  url.searchParams.set("timeEnd", params.timeEnd.toISOString());

  const res = await client.get<GetLeaderboardResponseDTO>(url.toString());

  return res.data;
};
