import { client } from "../client";

type RegisterUserRequestDTO = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};
type RegisterUserResponseDTO = {
  accessToken: string;
  userId: string;
  companyId: string;
};

export const registerUser = async (params: RegisterUserRequestDTO) => {
  const res = await client.post<RegisterUserResponseDTO>(
    "/auth/local/register",
    {
      ...params,
    }
  );

  return res.data;
};
