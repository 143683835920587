import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Card, CardContent } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Activity } from "../data/activities/get-home";
import { formatDate, formatPace, formatTime } from "../lib/date-utils";
import { getDisplayName, getFallbackName } from "../lib/name-utils";
import {
  getIconBySportType,
  getPaceFormatBySportType,
} from "../lib/sport-type-utils";
import { useAuthStore } from "../stores/auth.store";
import { ApplyXPBoost } from "./dialogs/apply-xp-boost-dialog";
import Typography from "./typography";
import { Spinner } from "./ui/spinner";

type SportActivitiesListProps = {
  activities: Activity[];
  isLoading: boolean;
};

export function SportActivitiesList(props: SportActivitiesListProps) {
  const navigate = useNavigate();

  const userId = useAuthStore((store) => store.userId);

  const ActivityDetail = ({
    label,
    icon,
    value,
    unit,
  }: {
    label: string;
    icon: string;
    value: string;
    unit?: string;
  }) => (
    <div className="flex flex-col">
      <p className="text-xs font-medium text-muted-foreground">
        {label} {icon}
      </p>
      <div className="flex items-baseline">
        <p className="text-sm font-bold">{value}</p>
        {unit && <p className="text-xs font-light ml-1">{unit}</p>}
      </div>
    </div>
  );

  return (
    <div className="bg-white rounded-lg border border-slate-200 p-2 sm:p-4 h-full">
      {!props.isLoading && props.activities.length === 0 && (
        <div className="flex flex-col h-full items-center justify-center">
          <Typography variant="h4">Welcome to Miles!</Typography>
          <Typography variant="p" className="text-center">
            There are no activities yet, so this page won't be very exciting{" "}
            <b>
              <i>for now</i>
            </b>{" "}
            😉
          </Typography>
        </div>
      )}

      {props.isLoading && (
        <div className="flex flex-col h-full items-center justify-center">
          <Spinner className="text-blue-400" size="large" />
        </div>
      )}

      {props.activities.length > 0 && (
        <ScrollArea className="h-[calc(100vh-2rem)]">
          <div className="space-y-4">
            {props.activities.map((activity) => (
              <Card key={activity.id} className="overflow-hidden rounded-md">
                <CardContent className="p-3">
                  <div className="flex items-center justify-between mb-3">
                    <div className="flex items-center">
                      <Avatar
                        className="h-10 w-10 hover:cursor-pointer"
                        onClick={() =>
                          navigate(`/profile/${activity.author.id}`)
                        }
                      >
                        <AvatarImage
                          src={activity.author.avatarUrl}
                          alt={`${activity.author.firstName}'s avatar`}
                        />
                        <AvatarFallback>
                          {getFallbackName(
                            activity.author.firstName,
                            activity.author.lastName
                          )}
                        </AvatarFallback>
                      </Avatar>
                      <div className="ml-3">
                        <Typography
                          variant="h3"
                          affects="link"
                          className="text-sm font-semibold"
                          onClick={() =>
                            navigate(`/profile/${activity.author.id}`)
                          }
                        >
                          {getDisplayName(
                            activity.author.firstName,
                            activity.author.lastName
                          )}
                        </Typography>
                        <p className="text-xs text-muted-foreground">
                          {activity.author.team
                            ? `${activity.author.team.codeName} • `
                            : ""}
                          Level {activity.author.level} •{" "}
                          {formatDate(activity.createdAt)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-7 gap-3 text-sm">
                    <ActivityDetail
                      label="Sport"
                      icon={getIconBySportType(activity.type)}
                      value={
                        activity.type.charAt(0).toUpperCase() +
                        activity.type.slice(1)
                      }
                    />
                    <ActivityDetail
                      label="Distance"
                      icon="📏"
                      value={activity.distance.toFixed(1)}
                      unit="KM"
                    />
                    <ActivityDetail
                      label="Duration"
                      icon="⏱️"
                      value={formatTime(activity.duration, "HH:mm:ss")}
                    />
                    <ActivityDetail
                      label="Pace"
                      icon="💨"
                      value={formatPace(
                        activity.duration,
                        activity.distance,
                        activity.type
                      ).toString()}
                      unit={getPaceFormatBySportType(activity.type)}
                    />
                    <ActivityDetail
                      label="Elevation"
                      icon="🏔️"
                      value={activity.elevation.toFixed()}
                      unit="M"
                    />
                    <ActivityDetail
                      label="Experience"
                      icon="⭐"
                      value={activity.experience.toFixed()}
                      unit="EXP"
                    />

                    <div className="flex flex-col">
                      <p className="text-xs font-medium text-muted-foreground">
                        Bonus XP 💫
                      </p>

                      <div className="flex items-baseline">
                        {activity.author.id === userId &&
                        !activity.xpBoostId &&
                        dayjs(activity.createdAt).add(1, "day").toDate() >=
                          new Date() ? (
                          <ApplyXPBoost
                            activityId={activity.id}
                            activityExp={activity.experience}
                            activityCreatedAt={activity.createdAt}
                          />
                        ) : (
                          <div className="flex items-baseline">
                            <p
                              className={`text-sm font-bold ${
                                activity.bonusExp > 0 && "animate-rainbow"
                              }`}
                            >
                              {activity.bonusExp}
                            </p>
                            <p className="text-xs font-light ml-1">
                              EXP{" "}
                              {activity.bonusExp > 0 &&
                                `(${Math.round(
                                  (activity.bonusExp / activity.experience) *
                                    100
                                )}%)`}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </ScrollArea>
      )}
    </div>
  );
}
