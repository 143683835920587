import { client } from "../../client";

type CreateGridResponseDTO = {
  id: string;
};

export const createGrid = async () => {
  const res = await client.post<CreateGridResponseDTO>(`/grid-reveal`);

  return res.data;
};
