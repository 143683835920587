import { FullTrainingPlan } from "../data/training-plans/types";

export const calculateTotalXPReward = (
  trainingPlan: FullTrainingPlan
): number => {
  if (trainingPlan.isAbandonned || !trainingPlan.isCompleted) {
    return 0;
  }

  const lastWeek = trainingPlan.weeks[trainingPlan.weeks.length - 1];

  const minProgressForReward = lastWeek.objective * 0.8;
  const hasEnoughProgress = lastWeek.progress >= minProgressForReward;

  if (!hasEnoughProgress) {
    return 0;
  }

  let totalRewardXP = 0;

  for (const week of trainingPlan.weeks) {
    const minWeekProgressForReward = week.objective * 0.8;

    if (week.progress >= minWeekProgressForReward) {
      totalRewardXP += week.expReward;
    }
  }

  return totalRewardXP;
};
