import { formatDate } from "../../../lib/date-utils";
import { ProcessedBoost } from "../../../lib/xp-forge-utils";
import Typography from "../../typography";
import { Badge } from "../badge";

export const ForgeHistoryItem = ({
  date,
  inputBoosts,
  outputBoost,
}: ProcessedBoost) => {
  return (
    <div className="flex flex-col gap-2 sm:flex-row sm:gap-1 w-full items-center">
      <Badge variant="outline" className="border-blue-200 w-36 justify-center">
        <Typography variant="p" affects="muted" className="text-xs">
          {formatDate(date)}
        </Typography>
      </Badge>

      <div className="flex flex-row gap-2 sm:gap-1">
        <Badge
          variant="secondary"
          className="bg-blue-400 bg-opacity-10 text-md w-10 justify-center"
        >
          <Typography
            variant="p"
            affects="muted"
            className="text-blue-400 text-xs"
          >
            {inputBoosts[0]}%
          </Typography>
        </Badge>

        {"+"}

        <Badge
          variant="secondary"
          className="bg-blue-400 bg-opacity-10 text-md w-10 justify-center"
        >
          <Typography
            variant="p"
            affects="muted"
            className="text-blue-400 text-xs"
          >
            {inputBoosts[1]}%
          </Typography>
        </Badge>

        {"+"}

        <Badge
          variant="secondary"
          className="bg-blue-400 bg-opacity-10 text-md w-10 justify-center"
        >
          <Typography
            variant="p"
            affects="muted"
            className="text-blue-400 text-xs"
          >
            {inputBoosts[2]}%
          </Typography>
        </Badge>

        {"→"}

        <Badge
          variant="secondary"
          className="bg-blue-400 bg-opacity-10 text-md w-12 justify-center"
        >
          <Typography
            variant="p"
            affects="muted"
            className="text-blue-400 text-xs"
          >
            {outputBoost}%
          </Typography>
        </Badge>
      </div>
    </div>
  );
};
