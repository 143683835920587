import { PlusIcon } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { ProfileActivity } from "../../../data/activities/get-user-activities";
import { User } from "../../../data/auth/get-user";
import { UserStats } from "../../../data/auth/get-user-stats";
import { Friend } from "../../../data/profile/get-friends";
import { getFallbackName } from "../../../lib/name-utils";
import { useAuthStore } from "../../../stores/auth.store";
import { UserTotalDistances } from "../../cards/user-total-distance";
import { XPBoostStatsCard } from "../../cards/xp-boost-stats";
import { UserXPContribution } from "../../charts/user-exp-contribution";
import { ActivitiesTable } from "../../tables/activities";
import Typography from "../../typography";
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar";
import { Button } from "../button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../tabs";

type ProfileContentProps = {
  userId: string;
  userStats?: UserStats;
  activities: ProfileActivity[];
  friends: Friend[];
  user?: User;
};

export const ProfileContent = (props: ProfileContentProps) => {
  const navigate = useNavigate();

  const loggedInUserId = useAuthStore((store) => store.userId);

  return (
    <div className="flex flex-col md:flex-row w-full rounded-lg gap-4">
      <div className="w-full flex flex-col gap-4 md:w-2/3 order-2 md:order-1 pb-20 md:pb-4">
        <div className="flex flex-col gap-2 bg-white rounded-lg border border-slate-200 p-4">
          <Typography variant="h3">Features</Typography>

          <div className="grid grid-cols-2 gap-4">
            <div
              className="p-2 bg-blue-100 rounded-md hover:bg-blue-200 transition-colors cursor-pointer"
              onClick={() => navigate(`/grid-reveal/${props.userId}`)}
            >
              <Typography variant="p" className="text-md font-bold">
                Grid Reveal
              </Typography>

              <Typography variant="p" className="text-xs">
                Gain XP Boosts by claiming cells
              </Typography>
            </div>

            <div
              className={`p-2 bg-blue-100 rounded-md hover:bg-blue-200 transition-colors cursor-pointer ${
                props.userId !== loggedInUserId && "hidden"
              }`}
              onClick={() => navigate("/xp-forge")}
            >
              <Typography variant="p" className="text-md font-bold">
                XP Forge
              </Typography>

              <Typography variant="p" className="text-xs">
                Merge XP boosts together
              </Typography>
            </div>
          </div>

          <div
            className={`p-2 bg-gray-100 rounded-md hover:bg-blue-200 transition-colors cursor-pointer ${
              props.userId !== loggedInUserId && "hidden"
            }`}
            onClick={() => navigate("/training-plans")}
          >
            <Typography variant="p" className="text-md font-bold">
              Training Plans
            </Typography>

            <Typography variant="p" className="text-xs">
              Use Miles' recommendations to get to your objective 🚀
            </Typography>
          </div>
        </div>

        <ActivitiesTable activities={props.activities} showUserColumn={false} />
      </div>

      <div className="w-full md:w-1/3 order-1 md:order-2 flex flex-col gap-4">
        <div className="flex flex-col gap-2 bg-white rounded-lg border border-slate-200 p-4">
          <div className="w-full flex flex-row justify-between items-center">
            <div className="flex flex-row items-end gap-3">
              <Typography
                variant="h3"
                className="hover:text-blue-400 hover:cursor-pointer"
                onClick={() =>
                  navigate(
                    `/friends${
                      loggedInUserId !== props.userId ? `/${props.userId}` : ""
                    }`
                  )
                }
              >
                Friends
              </Typography>

              <Typography
                variant="h3"
                affects="light"
                className="text-blue-500"
              >
                {props.friends.length}
              </Typography>
            </div>

            {props.userId === loggedInUserId && (
              <Button
                variant="outline"
                size="icon"
                className="rounded-full"
                onClick={() =>
                  navigate("/friends", {
                    state: {
                      defaultTab: "add",
                    },
                  })
                }
              >
                <PlusIcon className="h-4 w-4" />
              </Button>
            )}
          </div>

          <div className="grid grid-cols-8 grid-rows-1 gap-2">
            {props.friends.slice(0, 6).map((friend) => (
              <Avatar
                key={friend.id}
                className="hover:cursor-pointer"
                onClick={() =>
                  navigate(`/profile/${friend.id}`, { replace: true })
                }
              >
                <AvatarImage src={friend?.avatarUrl} />
                <AvatarFallback>
                  {getFallbackName(friend.firstName, friend.lastName)}
                </AvatarFallback>
              </Avatar>
            ))}

            {props.friends.length === 7 && (
              <Avatar
                key={props.friends[6].id}
                className="hover:cursor-pointer"
                onClick={() =>
                  navigate(`/profile/${props.friends[6].id}`, { replace: true })
                }
              >
                <AvatarImage src={props.friends[6]?.avatarUrl} />
                <AvatarFallback>
                  {getFallbackName(
                    props.friends[6].firstName,
                    props.friends[6].lastName
                  )}
                </AvatarFallback>
              </Avatar>
            )}

            {props.friends.length > 7 && (
              <div className="relative inline-block">
                {/* special on click to friends page */}
                <Avatar>
                  <AvatarImage src={props.friends[6]?.avatarUrl} />
                  <AvatarFallback>
                    {getFallbackName(
                      props.friends[6].firstName,
                      props.friends[6].lastName
                    )}
                  </AvatarFallback>
                </Avatar>

                <div className="absolute inset-0 aspect-square h-12 w-12 flex items-center justify-center bg-black bg-opacity-40 rounded">
                  <p className="text-white text-lg font-bold">
                    +{props.friends.length - 6}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <Tabs defaultValue="distance" className="w-full">
          <TabsList className="w-full">
            <TabsTrigger className="flex-1" value="distance">
              Distance
            </TabsTrigger>

            <TabsTrigger className="flex-1" value="experience">
              Experience
            </TabsTrigger>

            <TabsTrigger className="flex-1" value="xpboosts">
              XP Boosts
            </TabsTrigger>
          </TabsList>

          <TabsContent value="distance">
            <UserTotalDistances
              bikingDistance={props.userStats?.biking?.totalDistance || 0}
              runningDistance={props.userStats?.running?.totalDistance || 0}
              swimmingDistance={props.userStats?.swimming?.totalDistance || 0}
              walkingDistance={props.userStats?.walking?.totalDistance || 0}
            />
          </TabsContent>

          <TabsContent value="experience">
            <UserXPContribution
              companyContribution={props.userStats?.companyContribution || 0}
              companyLevel={props.user?.companyLevel || 1}
            />
          </TabsContent>

          <TabsContent value="xpboosts">
            <XPBoostStatsCard
              stats={
                props.userStats?.xpBoosts || {
                  nbApplied: 0,
                  nbAvailable: 0,
                  nbForged: 0,
                  totalPercentAvailable: 0,
                  totalPercentUsed: 0,
                  totalXPGiven: 0,
                }
              }
            />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};
