import { client } from "../../client";

export type GridOverview = {
  id: string;
  userId: string;
  level: number;
  createdAt: string;
};

type GetGridsRequestDTO = {
  userId: string;
};

type GetGridsResponseDTO = GridOverview[];

export const getGrids = async (params: GetGridsRequestDTO) => {
  const res = await client.get<GetGridsResponseDTO>(`/grid-reveal`, {
    params: {
      userId: params.userId,
    },
  });

  return res.data;
};
