import { client } from "../client";

export type ApplyXPBoostsResponseDTO = {
  xpBoostId: string;
  activityId: string;
};

export const applyXPBoost = async (params: ApplyXPBoostsResponseDTO) => {
  const res = await client.post(`/xp-boosts/${params.xpBoostId}/apply`, {
    activityId: params.activityId,
  });

  return res.data;
};
