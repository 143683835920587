import React from "react";
import { useNavigate } from "react-router-dom";
import {
  LeaderboardTeam,
  LeaderboardUser,
} from "../../../data/home/get-leaderboard";
import { getDisplayName } from "../../../lib/name-utils";
import Typography from "../../typography";
import { Avatar, AvatarImage } from "../avatar";

type PodiumProps = {
  first?: LeaderboardUser | LeaderboardTeam;
  second?: LeaderboardUser | LeaderboardTeam;
  third?: LeaderboardUser | LeaderboardTeam;
};

const PodiumItem: React.FC<{
  item: LeaderboardUser | LeaderboardTeam;
  place: number;
}> = ({ item, place }) => {
  const navigate = useNavigate();
  const isUser = "userId" in item;

  const handleClick = () => {
    if (isUser) {
      navigate(`/profile/${item.userId}`);
    }
  };

  const bgColor =
    place === 1
      ? "bg-orange-300"
      : place === 2
      ? "bg-slate-300"
      : "bg-teal-200";

  const height = place === 1 ? "h-24" : place === 2 ? "h-20" : "h-16";

  return (
    <div className="h-full w-full flex justify-between flex-col">
      <div className="w-full flex-1 justify-end items-center flex flex-col gap-2">
        {isUser && item.avatarUrl && (
          <Avatar
            affects="noborder"
            className="hover:cursor-pointer"
            onClick={handleClick}
          >
            <AvatarImage src={item.avatarUrl} />
          </Avatar>
        )}

        <Typography
          variant="p"
          className={
            isUser
              ? "text-xs hover:cursor-pointer hover:text-blue-400"
              : "text-lg"
          }
          onClick={handleClick}
        >
          {isUser
            ? `${getDisplayName(item.firstName, item.lastName)}`
            : item.teamCodeName}
        </Typography>

        {isUser && (
          <Typography
            variant="p"
            affects="muted"
            className="text-xs mt-[-10px]"
          >
            {item.teamCodeName}
          </Typography>
        )}
      </div>

      <div
        className={`${height} w-full ${bgColor} rounded-t-lg text-center pt-2 flex flex-col justify-between p-2`}
      >
        <Typography variant="h3" className="text-white">
          {place}
        </Typography>
        <Typography variant="p" affects="small" className="text-white">
          {item.experience} EXP
        </Typography>
      </div>
    </div>
  );
};

export const Podium: React.FC<PodiumProps> = ({ first, second, third }) => {
  return (
    <div className="flex flex-row gap-3 w-full h-full items-end justify-center">
      {second && <PodiumItem item={second} place={2} />}
      {first && <PodiumItem item={first} place={1} />}
      {third && <PodiumItem item={third} place={3} />}
    </div>
  );
};
