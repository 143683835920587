import Typography from "../typography";

export const SportsLegend = () => {
  return (
    <div className="w-full flex flex-wrap justify-center gap-4 mt-4 px-2">
      <div className="flex items-center gap-2">
        <div className="w-3 h-3 rounded-md bg-charts-chart1" />
        <Typography variant="p" affects="light" className="text-xs sm:text-sm">
          Biking
        </Typography>
      </div>
      <div className="flex items-center gap-2">
        <div className="w-3 h-3 rounded-md bg-charts-chart2" />
        <Typography variant="p" affects="light" className="text-xs sm:text-sm">
          Running
        </Typography>
      </div>
      <div className="flex items-center gap-2">
        <div className="w-3 h-3 rounded-md bg-charts-chart3" />
        <Typography variant="p" affects="light" className="text-xs sm:text-sm">
          Walking
        </Typography>
      </div>
      <div className="flex items-center gap-2">
        <div className="w-3 h-3 rounded-md bg-charts-chart4" />
        <Typography variant="p" affects="light" className="text-xs sm:text-sm">
          Swimming
        </Typography>
      </div>
    </div>
  );
};
