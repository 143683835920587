import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cancelFriendRequest } from "../../../data/profile/cancel-friend-request";
import {
  FriendRequest,
  getPendingFriendRequests,
} from "../../../data/profile/get-friend-requests";
import { handleFriendRequest } from "../../../data/profile/handle-friend-request";
import {
  searchUsers,
  UserSearchResult,
} from "../../../data/profile/search-users";
import { sendFriendRequest } from "../../../data/profile/send-friend-request";
import { useAuthStore } from "../../../stores/auth.store";
import Typography from "../../typography";
import { Input } from "../input";
import { FriendCard } from "./friend-card";

export const FriendRequestsList = () => {
  const navigate = useNavigate();

  const userId = useAuthStore((store) => store.userId);

  const [nameFilter, setNameFilter] = useState<string>("");
  const debouncedNameFilter = useDebounce(nameFilter, 300);

  const [pendingRequests, setPendingRequests] = useState<FriendRequest[]>([]);
  const [searchResults, setSearchResults] = useState<UserSearchResult[]>([]);

  const loadUserPendingRequests = async () => {
    const data = await getPendingFriendRequests();

    const sortedData = data.sort((a, b) => {
      const aIsReceived = a.receiver.id === userId;
      const bIsReceived = b.receiver.id === userId;

      if (aIsReceived && !bIsReceived) {
        return -1;
      }
      if (bIsReceived && !aIsReceived) {
        return 1;
      }
      return 0;
    });

    setPendingRequests(sortedData);
  };

  const searchUsersByName = async () => {
    const name = nameFilter.trim();

    if (name.length >= 3) {
      const data = await searchUsers({
        name,
      });

      setSearchResults(data);
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    loadUserPendingRequests();
  }, []);

  useEffect(() => {
    searchUsersByName();
  }, [debouncedNameFilter]);

  const getFriendFromRequest = (friendRequest: FriendRequest) => {
    return friendRequest.sender.id === userId
      ? friendRequest.receiver
      : friendRequest.sender;
  };

  const filterByName = (friendRequest: FriendRequest) => {
    const friend = getFriendFromRequest(friendRequest);

    return `${friend.firstName.toLowerCase()} ${friend.lastName.toLowerCase()}`.includes(
      nameFilter.toLowerCase()
    );
  };

  const onHandleFriendRequest = async (
    friendRequestId: string,
    accepted: boolean
  ) => {
    await handleFriendRequest({
      friendRequestId,
      accepted,
    });

    loadUserPendingRequests();

    if (accepted) {
      return navigate(0);
    }
  };

  const onCancelFriendRequest = async (friendRequestId: string) => {
    await cancelFriendRequest({
      friendRequestId,
    });

    loadUserPendingRequests();
  };

  const onClickSendFriendRequest = async (userId: string) => {
    await sendFriendRequest({
      userId,
    });

    setNameFilter("");
    setSearchResults([]);
    loadUserPendingRequests();
  };

  return (
    <div className="flex flex-col w-full gap-2 p-4 bg-white border border-gray-200 rounded-md">
      <div className="flex flex-row justify-between w-full">
        <Typography variant="h3">Add friends</Typography>

        <Input
          type="text"
          className="w-64"
          placeholder="Find users by name..."
          value={nameFilter}
          onChange={(value) => setNameFilter(value.currentTarget.value)}
        />
      </div>

      {pendingRequests
        .filter((pr) => filterByName(pr))
        .map((fr) => (
          <FriendCard
            key={fr.id}
            friend={getFriendFromRequest(fr)}
            badgeText={fr.sender.id === userId ? "PENDING" : undefined}
            onClickRemove={
              fr.receiver.id === userId
                ? () => onHandleFriendRequest(fr.id, false)
                : () => onCancelFriendRequest(fr.id)
            }
            onClickAccept={
              fr.receiver.id === userId
                ? () => onHandleFriendRequest(fr.id, true)
                : undefined
            }
          />
        ))}

      {searchResults.map((sr) => (
        <FriendCard
          key={sr.id}
          friend={sr}
          onClickSend={() => onClickSendFriendRequest(sr.id)}
        />
      ))}

      {pendingRequests.filter((pr) => filterByName(pr)).length === 0 &&
        searchResults.length === 0 &&
        nameFilter.length >= 3 && <p>No results for {nameFilter}.</p>}
    </div>
  );
};
