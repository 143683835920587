import { client } from "../client";

export type ProfileBackground = {
  id: string;
  type: "solid" | "gradient" | "image";
  style: string;
};

export type GetProfileBackgroundsResponseDTO = ProfileBackground[];

export const getProfileBackgrounds = async () => {
  const res = await client.get<GetProfileBackgroundsResponseDTO>(
    `/backgrounds`
  );

  return res.data;
};
