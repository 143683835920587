import { useMediaQuery } from "@uidotdev/usehooks";
import { ShieldCheck } from "lucide-react";
import { useEffect, useState } from "react";
import { SportActivitiesList } from "../components/sport-activities-list";
import { Alert, AlertDescription, AlertTitle } from "../components/ui/alert";
import { Leaderboard } from "../components/ui/leaderboards/leaderboard";
import { Top5LastMonth } from "../components/ui/leaderboards/top-5-last-month";
import { ScrollArea } from "../components/ui/scroll-area";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { Activity, getHomeActivities } from "../data/activities/get-home";
import { useAuthStore } from "../stores/auth.store";

export const Home = () => {
  const isSmallDevice = useMediaQuery("only screen and (max-width: 768px)");

  const companyId = useAuthStore((store) => store.companyId);
  const isVerified = useAuthStore((store) => store.isVerified);

  const [isLoadingActivities, setIsLoadingActivities] = useState<boolean>(true);
  const [activities, setActivities] = useState<Activity[]>([]);

  const [activeTab, setActiveTab] = useState("activities");

  useEffect(() => {
    const getActivities = async () => {
      setIsLoadingActivities(true);

      const acts = await getHomeActivities();
      setActivities(acts);

      setIsLoadingActivities(false);
    };

    getActivities();
  }, [companyId]);

  return (
    <div className="w-full h-full flex flex-col overflow-y-auto gap-2">
      {!isVerified && (
        <Alert className="border-blue-400 mb-2">
          <AlertTitle className="flex flex-row gap-2">
            <ShieldCheck className="w-4 h-4" />
            Email verification
          </AlertTitle>

          <AlertDescription>
            Claim your ⭐ <b>100% XP BOOST</b> ⭐ by{" "}
            <a href="/settings" className="text-blue-400">
              verifying your email
            </a>{" "}
            !
          </AlertDescription>
        </Alert>
      )}

      <Tabs
        defaultValue={activeTab}
        className="w-full h-full flex flex-col overflow-hidden"
      >
        <div className="flex justify-center lg:hidden mb-2">
          <TabsList className="inline-flex">
            <TabsTrigger
              value="activities"
              onClick={() => setActiveTab("activities")}
            >
              Recent Activities
            </TabsTrigger>
            <TabsTrigger
              value="leaderboards"
              onClick={() => setActiveTab("leaderboards")}
            >
              Leaderboards
            </TabsTrigger>
          </TabsList>
        </div>

        <div className="flex-grow flex flex-col lg:flex-row gap-2 pt-2 sm:pt-0 overflow-hidden">
          <TabsContent
            value="activities"
            className="mt-0 flex-grow lg:w-3/4 h-full"
          >
            <SportActivitiesList
              activities={activities}
              isLoading={isLoadingActivities}
            />
          </TabsContent>

          {isSmallDevice && (
            <TabsContent
              value="leaderboards"
              className="mt-0 flex-grow lg:hidden h-full flex flex-col gap-2"
            >
              <div className="flex-shrink-0">
                <Top5LastMonth />
              </div>

              <ScrollArea>
                <div className="flex-grow">
                  <Leaderboard />
                </div>
              </ScrollArea>
            </TabsContent>
          )}

          {!isSmallDevice && (
            <div className="hidden lg:flex lg:flex-col lg:w-1/4 gap-4">
              <div className="h-min">
                <Top5LastMonth />
              </div>

              <div className="h-min">
                <Leaderboard />
              </div>
            </div>
          )}
        </div>
      </Tabs>
    </div>
  );
};
