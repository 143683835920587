import { REGEXP_ONLY_DIGITS_AND_CHARS } from "input-otp";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import Typography from "../components/typography";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from "../components/ui/input-otp";
import { Label } from "../components/ui/label";
import { Progress } from "../components/ui/progress";
import { changePassword } from "../data/auth/change-password";
import { resetPassword } from "../data/auth/reset-password";

enum STEP {
  REQUEST_CODE,
  SET_NEW_PW,
}

export const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");

  const [step, setStep] = useState<STEP>(STEP.REQUEST_CODE);

  const onClickSendCode = async () => {
    const trimmedEmail = email.trim();

    if (!trimmedEmail) {
      toast("An error occurred", {
        description: "You must provide an email address.",
      });

      return;
    }

    await resetPassword({
      email: trimmedEmail,
    });

    setStep(STEP.SET_NEW_PW);
  };

  const onClickChangePassword = async () => {
    const trimmedCode = code.trim();

    if (!trimmedCode) {
      toast("An error occurred", {
        description: "You must provide the security code to proceed.",
      });

      return;
    }

    const trimmedNewPw = newPassword.trim();

    if (!trimmedNewPw) {
      toast("An error occurred", {
        description: "You must provide a new password to proceed.",
      });

      return;
    }

    await changePassword({
      email: email.trim(),
      code: trimmedCode,
      newPassword: trimmedNewPw,
    });

    navigate("/login");
  };

  return (
    <div className="flex flex-row h-full">
      <div className="w-full md:w-1/2 flex content-center justify-center items-center p-4 md:p-8">
        <div className="flex flex-col text-center items-center gap-5 w-full max-w-sm">
          {/* Titles */}
          <div>
            <Typography variant="h1" className="text-2xl md:text-4xl">
              Forgot your password?
            </Typography>
            <Typography
              variant="p"
              className="text-slate-600 text-sm md:text-base"
            >
              Change it in two easy steps.
            </Typography>
          </div>

          {/* Stepper */}
          <div className="flex flex-row w-full gap-4 justify-center">
            <Progress
              value={step === STEP.REQUEST_CODE ? 100 : 0}
              className="h-4 w-16 -scale-x-100"
              onClick={() => setStep(STEP.REQUEST_CODE)}
            />
            <Progress
              value={step === STEP.SET_NEW_PW ? 100 : 0}
              className="h-4 w-16"
            />
          </div>

          {step === STEP.REQUEST_CODE && (
            <div className="flex flex-col gap-1 text-left w-full">
              <Label htmlFor="email">Email</Label>

              <div className="flex flex-col sm:flex-row items-center gap-2">
                <Input
                  id="email"
                  type="email"
                  placeholder="john.doe@example.com"
                  className="w-full"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <Button
                  type="submit"
                  onClick={onClickSendCode}
                  className="w-full sm:w-auto mt-2 sm:mt-0"
                >
                  Send code
                </Button>
              </div>

              <Typography
                variant="p"
                affects="muted"
                className="text-xs md:text-sm"
              >
                You will be sent a security code to use in the next step.
              </Typography>
            </div>
          )}

          {step === STEP.SET_NEW_PW && (
            <div className="flex flex-col gap-2 text-left w-full">
              <Label htmlFor="newPassword">New password</Label>
              <Input
                id="newPassword"
                type="password"
                placeholder="*********************"
                onChange={(e) => setNewPassword(e.target.value)}
              />

              <Label htmlFor="code">Code</Label>
              <InputOTP
                id="code"
                maxLength={8}
                pattern={REGEXP_ONLY_DIGITS_AND_CHARS}
                onChange={(e) => setCode(e)}
              >
                <InputOTPGroup>
                  <InputOTPSlot index={0} />
                  <InputOTPSlot index={1} />
                  <InputOTPSlot index={2} />
                  <InputOTPSlot index={3} />
                  <InputOTPSlot index={4} />
                  <InputOTPSlot index={5} />
                  <InputOTPSlot index={6} />
                  <InputOTPSlot index={7} />
                </InputOTPGroup>
              </InputOTP>

              <Button className="mt-4" onClick={onClickChangePassword}>
                Change password
              </Button>
            </div>
          )}

          {/* Sub text go back */}
          <div className="flex flex-row w-full justify-center">
            <Link to="/login">
              <Typography
                variant="p"
                className="text-blue-400 underline text-sm"
              >
                Back to login
              </Typography>
            </Link>
          </div>
        </div>
      </div>

      <div className="w-1/2 h-full bg-[url('/runner.jpg')] bg-cover bg-center bg-no-repeat hidden md:block" />
    </div>
  );
};
