import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserProfileHeader } from "../components/cards/user-profile-header";
import { ProfileContent } from "../components/ui/profile/profile-content";
import {
  getUserActivities,
  ProfileActivity,
} from "../data/activities/get-user-activities";
import { getUserById, User } from "../data/auth/get-user";
import { getUserStats, UserStats } from "../data/auth/get-user-stats";
import { Friend, getFriends } from "../data/profile/get-friends";
import { useAuthStore } from "../stores/auth.store";

export const Profile = () => {
  const params = useParams();

  const loggedInUserId = useAuthStore((store) => store.userId);
  const setCompanyId = useAuthStore((store) => store.setCompanyId);
  const setIsVerified = useAuthStore((store) => store.setIsVerified);

  const userId = params?.userId || loggedInUserId;

  const [user, setUser] = useState<User>();
  const [userStats, setUserStats] = useState<UserStats>();
  const [activities, setActivities] = useState<ProfileActivity[]>([]);
  const [friends, setFriends] = useState<Friend[]>([]);

  useEffect(() => {
    const loadUserAndStats = async () => {
      const data = await getUserById({
        userId,
      });

      setUser(data);

      // This is a workaround; update the companyId whenever we fetch the user's profile.
      if (userId === loggedInUserId) {
        setCompanyId(data.companyId, data.isCompanyAdmin || false);
        setIsVerified(data.isVerified!);
      }

      const stats = await getUserStats({
        userId,
      });

      setUserStats(stats);
    };

    const loadUserFriends = async () => {
      const data = await getFriends({
        userId,
      });

      setFriends(data);
    };

    const loadActivities = async () => {
      const data = await getUserActivities({
        userId,
      });

      setActivities(data);
    };

    loadUserAndStats();
    loadUserFriends();
    loadActivities();
  }, [userId, loggedInUserId, setCompanyId]);

  return (
    <div className="flex flex-col gap-2">
      <UserProfileHeader
        activeSinceDays={userStats?.activeSinceDays || 0}
        avatarUrl={user?.avatarUrl || ""}
        backgroundStyle={user?.profileBackground?.style || ""}
        experience={user?.experience || 0}
        firstName={user?.firstName || ""}
        lastName={user?.lastName || ""}
        level={user?.level || 0}
        teamName={user?.team?.name || ""}
        teamCodeName={user?.team?.codeName || ""}
        totalActivities={userStats?.nbActivities || 0}
        totalTime={userStats?.totalDuration || 0}
      />

      <ProfileContent
        activities={activities}
        friends={friends}
        user={user}
        userId={userId}
        userStats={userStats}
      />
    </div>
  );
};
