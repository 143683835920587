import { client } from "../client";

export type MonthlyActivityStats = {
  monthIdx: number;
  monthName: string;
  nbRides: number;
  nbRuns: number;
  nbWalks: number;
  nbSwims: number;
};

type GetCompanyActivityStatsRequestDTO = {
  companyId: string;
};
type GetCompanyActivityStatsResponseDTO = MonthlyActivityStats[];

export const getCompanyActivityStats = async (
  props: GetCompanyActivityStatsRequestDTO
) => {
  const res = await client.get<GetCompanyActivityStatsResponseDTO>(
    `/companies/${props.companyId}/monthly-activities`
  );

  return res.data;
};
