import { client } from "../client";

type DeleteActivityRequestDTO = {
  activityId: string;
};

export const deleteActivity = async (params: DeleteActivityRequestDTO) => {
  const res = await client.delete(`/activities/${params.activityId}`);

  return res.data;
};
