import { client } from "../client";

type BuyMinigameUpgradeRequestDTO = {
  upgradeId: string;
};

export const buyMinigameUpgrade = async (
  params: BuyMinigameUpgradeRequestDTO
) => {
  const res = await client.post(`/upgrades/${params.upgradeId}/buy`);

  return res.data;
};
