import { XPBoost } from "../../activities/get-xp-boosts";
import { client } from "../../client";

type ForgeXPBoostRequestDTO = {
  boosts: string[];
};

type ForgeXPBoostResponseDTO = XPBoost;

export const forgeXPBoost = async (params: ForgeXPBoostRequestDTO) => {
  const res = await client.post<ForgeXPBoostResponseDTO>("/xp-boosts/forge", {
    boosts: params.boosts,
  });

  return res.data;
};
