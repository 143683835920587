import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import Typography from "../components/typography";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { useAuthStore } from "../stores/auth.store";

export const Register = () => {
  const register = useAuthStore((state) => state.register);

  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");

  const validateName = (name: string) => {
    // This pattern allows letters, spaces, hyphens, and apostrophes
    const pattern = /^[A-Za-z\s'-]+$/;
    return pattern.test(name);
  };

  const onClickRegister = async () => {
    const trimmedEmail = email.trim();
    const trimmedFirstName = firstName.trim();
    const trimmedLastName = lastName.trim();
    const trimmedPassword = password.trim();

    if (
      !trimmedEmail ||
      !trimmedFirstName ||
      !trimmedLastName ||
      !trimmedPassword
    ) {
      toast("An error occured", {
        description: "All fields are required to create an account.",
      });

      return;
    }

    if (!validateName(trimmedFirstName) || !validateName(trimmedLastName)) {
      toast("An error occured", {
        description: "First and last names cannot include special characters.",
      });

      return;
    }

    setIsLoading(true);

    try {
      await register(
        trimmedEmail,
        trimmedPassword,
        trimmedFirstName,
        trimmedLastName
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-row h-full">
      <div className="w-full md:w-1/2 flex content-center justify-center items-center p-4 md:p-8">
        <div className="flex flex-col text-center items-center gap-5 w-full max-w-sm">
          {/* Titles */}
          <div>
            <Typography variant="h1" className="text-2xl md:text-3xl">
              Register
            </Typography>
            <Typography
              variant="p"
              className="text-slate-600 text-sm md:text-base"
            >
              Create an account to join the Miles community!
            </Typography>
          </div>

          {/* Inputs */}
          <div className="flex flex-col items-center gap-4 w-full">
            {/* Email input */}
            <div className="flex flex-col w-full text-left gap-1">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                placeholder="john.doe@example.com"
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>

            {/* Name inputs */}
            <div className="flex flex-col md:flex-row items-center gap-4 w-full">
              {/* First name input */}
              <div className="flex flex-col text-left gap-1 w-full md:w-1/2">
                <Label htmlFor="firstName">First Name</Label>
                <Input
                  id="firstName"
                  type="text"
                  placeholder="John"
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </div>

              {/* Last name input */}
              <div className="flex flex-col text-left gap-1 w-full md:w-1/2">
                <Label htmlFor="lastName">Last Name</Label>
                <Input
                  id="lastName"
                  type="text"
                  placeholder="Doe"
                  onChange={(event) => setLastName(event.target.value)}
                />
              </div>
            </div>

            {/* Password input */}
            <div className="flex flex-col w-full text-left gap-1">
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                placeholder="*********************"
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
          </div>

          {/* Register button */}
          <Button
            className="w-full gap-1"
            onClick={onClickRegister}
            disabled={isLoading}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.00977 5.83789C3.00977 5.28561 3.45748 4.83789 4.00977 4.83789H20C20.5523 4.83789 21 5.28561 21 5.83789V17.1621C21 18.2667 20.1046 19.1621 19 19.1621H5C3.89543 19.1621 3 18.2667 3 17.1621V6.16211C3 6.11449 3.00333 6.06765 3.00977 6.0218V5.83789ZM5 8.06165V17.1621H19V8.06199L14.1215 12.9405C12.9499 14.1121 11.0504 14.1121 9.87885 12.9405L5 8.06165ZM6.57232 6.80554H17.428L12.7073 11.5263C12.3168 11.9168 11.6836 11.9168 11.2931 11.5263L6.57232 6.80554Z"
                fill="currentColor"
              />
            </svg>
            Register with Email
          </Button>

          {/* Sub text login */}
          <div className="flex flex-row w-full justify-center gap-2">
            <Typography variant="p" className="text-xs md:text-sm">
              Already have an account?
            </Typography>

            <Link to="/login">
              <Typography
                variant="p"
                className="text-blue-400 underline text-xs md:text-sm"
              >
                Log in
              </Typography>
            </Link>
          </div>
        </div>
      </div>

      <div className="w-1/2 h-full bg-[url('/runner.jpg')] bg-cover bg-center bg-no-repeat hidden md:block" />
    </div>
  );
};
