import { client } from "../client";

type LinkStravaRequestDTO = {
  code: string;
};

export const linkStrava = async (params: LinkStravaRequestDTO) => {
  const res = await client.post("/auth/strava", {
    code: params.code,
  });

  return res.data;
};
