import { useNavigate } from "react-router-dom";
import { TeamMember } from "../../../data/company/get-company";
import { joinTeam } from "../../../data/company/join-team";
import { getFallbackName } from "../../../lib/name-utils";
import { useAuthStore } from "../../../stores/auth.store";
import Typography from "../../typography";
import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar";
import { Button } from "../../ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";

type TeamItemProps = {
  id: string;
  name: string;
  shortName: string;
  members: TeamMember[];
  refreshCompanyData: () => void;
};

export const TeamItem = (props: TeamItemProps) => {
  const navigate = useNavigate();

  const companyId = useAuthStore((store) => store.companyId);

  const onClickChangeTeam = async () => {
    await joinTeam({
      companyId,
      teamId: props.id,
    });

    props.refreshCompanyData();
  };

  return (
    <div>
      {/* Name + options button */}
      <div className="group flex flex-row justify-between items-center w-full">
        <div className="flex flex-col text-left">
          <div className="flex flex-row gap-3">
            <Typography variant="h4">{props.name}</Typography>
            {props.members.length > 0 && (
              <Typography
                variant="h4"
                affects="light"
                className="text-blue-500"
              >
                {props.members.length}
              </Typography>
            )}
          </div>

          <div className="flex flex-row gap-1">
            <Typography variant="p" className="text-xs">
              Codename
            </Typography>
            <Typography variant="p" affects="muted" className="text-xs">
              {props.shortName}
            </Typography>
          </div>
        </div>

        <Dialog>
          <DialogTrigger asChild>
            <Button
              variant="outline"
              className="mr-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            >
              Join
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[425px] max-w-[90vw]">
            <DialogHeader>
              <DialogTitle className="text-center">
                Join team <b>{props.name}</b>?
              </DialogTitle>
              <DialogDescription className="text-center">
                This will switch you from your current team, if you're in one.
              </DialogDescription>
            </DialogHeader>
            <DialogFooter className="sm:flex-row flex-col gap-3 mt-5">
              <DialogClose asChild>
                <Button variant="secondary" className="sm:flex-1 w-full">
                  Cancel
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button
                  variant="default"
                  onClick={onClickChangeTeam}
                  className="sm:flex-1 w-full"
                >
                  Join team
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>

      {/* Members */}
      <div className="flex flex-row">
        {props.members.map((member, idx) => (
          <Avatar
            key={idx}
            affects={idx > 0 ? "overlaps" : "default"}
            className="h-10 w-10 hover:cursor-pointer"
            onClick={() => navigate(`/profile/${member.id}`)}
          >
            <AvatarImage src={member.avatarUrl} />
            <AvatarFallback>
              {getFallbackName(member.firstName, member.lastName)}
            </AvatarFallback>
          </Avatar>
        ))}
      </div>
    </div>
  );
};
