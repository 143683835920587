import { client } from "../client";

type Team = {
  id: string;
  codeName: string;
};

type CompanyMemberStats = {
  companyContribution: number;
  nbActivities: number;
  runningDistance: number;
  bikingDistance: number;
  swimmingDistance: number;
};

export type CompanyMember = {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  level: number;
  team?: Team;

  stats: CompanyMemberStats;
};

type GetCompanyMembersRequestDTO = {
  companyId: string;
};
type GetCompanyMembersResponseDTO = CompanyMember[];

export const getCompanyMembers = async (
  params: GetCompanyMembersRequestDTO
) => {
  const res = await client.get<GetCompanyMembersResponseDTO>(
    `/companies/${params.companyId}/members`
  );

  return res.data;
};
