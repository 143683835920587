import { useEffect, useState } from "react";
import { getUserIntegrations } from "../../../data/settings/get-user-integrations";
import { initiateLinkWithStrava } from "../../../lib/strava-utils";
import { useAuthStore } from "../../../stores/auth.store";
import Typography from "../../typography";
import { Button } from "../button";
import { Separator } from "../separator";

export const IntegrationsTab = () => {
  const userId = useAuthStore((store) => store.userId);
  const [stravaId, setStravaId] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserIntegrations = async () => {
      const integrations = await getUserIntegrations({ userId });
      setStravaId(integrations.stravaId);
    };

    fetchUserIntegrations();
  }, [userId]);

  return (
    <div className="w-full h-full bg-white rounded-lg border border-slate-200 p-4 flex flex-col gap-4">
      <div>
        <Typography variant="h3">Integrations</Typography>
        <Typography variant="p" affects="muted" className="text-sm">
          Connect your different social accounts for automatic activity uploads.
        </Typography>
      </div>

      <Separator />

      <div className="flex flex-col gap-1 h-full">
        <Typography variant="h4" className="text-orange-400">
          Strava
        </Typography>

        {stravaId ? (
          <a
            href={`https://www.strava.com/athletes/${stravaId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-orange-500 hover:text-orange-400"
          >
            View Strava Profile
          </a>
        ) : (
          <Button
            className="bg-orange-500 hover:bg-orange-400 w-full lg:w-2/3"
            onClick={initiateLinkWithStrava}
          >
            Link with Strava
          </Button>
        )}
      </div>
    </div>
  );
};
