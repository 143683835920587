import { AvatarIcon, GearIcon, HomeIcon } from "@radix-ui/react-icons";
import {
  Building,
  CalendarClock,
  MessageSquareQuote,
  Users,
} from "lucide-react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { UploadActivityDialog } from "../components/dialogs/upload-activity-dialog";
import { Button } from "../components/ui/button";

export const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const NavItem = ({
    href,
    icon: Icon,
    label,
  }: {
    href: string;
    label: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    icon: any;
  }) => (
    <Button
      variant="ghost"
      size="icon"
      className={`rounded-md ${pathname === href ? "bg-muted" : ""}`}
      onClick={() => navigate(href)}
      aria-label={label}
    >
      <Icon className="size-5" />
    </Button>
  );

  const DesktopNavBar = () => (
    <nav className="flex flex-col p-2 h-full justify-between">
      <div className="flex flex-col gap-2 h-full">
        <NavItem href="/home" icon={HomeIcon} label="Home" />
        <NavItem href="/profile" icon={AvatarIcon} label="Profile" />
        <NavItem href="/friends" icon={Users} label="Friends" />
        <NavItem href="/company" icon={Building} label="Company" />
        <NavItem
          href="/training-plans"
          icon={CalendarClock}
          label="Training Plans"
        />
        <UploadActivityDialog />
      </div>

      <div>
        <Button
          variant="ghost"
          size="icon"
          className="rounded-md"
          aria-label="Feedback"
          asChild
        >
          <a
            href="https://marcoverbeek.notion.site/12a025664adf80c68993d900dc16ddf8"
            target="_blank"
          >
            <MessageSquareQuote className="size-5" />
          </a>
        </Button>

        <NavItem href="/settings" icon={GearIcon} label="Settings" />
      </div>
    </nav>
  );

  const MobileNavBar = () => (
    <nav className="fixed bottom-0 left-0 right-0 z-10 bg-background border-t md:hidden">
      <div className="flex flex-row justify-around items-center h-16 px-4">
        <NavItem href="/home" icon={HomeIcon} label="Home" />
        <NavItem href="/profile" icon={AvatarIcon} label="Profile" />
        <NavItem href="/company" icon={Building} label="Company" />
        <NavItem
          href="/training-plans"
          icon={CalendarClock}
          label="Training Plans"
        />
        <UploadActivityDialog />

        <div>
          <NavItem href="/settings" icon={GearIcon} label="Settings" />
        </div>
      </div>
    </nav>
  );

  return (
    <div className="flex h-full w-full flex-col bg-muted/40">
      {/* Desktop sidebar */}
      <aside
        className={`fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background md:flex ${
          process.env.NODE_ENV !== "production" ? "bg-blue-100" : ""
        }`}
      >
        <DesktopNavBar />
      </aside>

      {/* Main content */}
      <div className="flex flex-col md:pl-14 h-full">
        <div className="p-4 pb-20 md:pb-4 h-full">
          <Outlet />
        </div>
      </div>

      {/* Mobile bottom navbar */}
      <MobileNavBar />
    </div>
  );
};
