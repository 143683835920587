import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getLeaderboard,
  LeaderboardUser,
} from "../../../data/home/get-leaderboard";
import { getDisplayName, getFallbackName } from "../../../lib/name-utils";
import { cn } from "../../../lib/utils";
import { useAuthStore } from "../../../stores/auth.store";
import Typography from "../../typography";
import { Avatar, AvatarFallback, AvatarImage } from "../avatar";
import { Tabs, TabsList, TabsTrigger } from "../tabs";

type ListItemProps = {
  position: number;

  userId: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  teamCodeName: string;
  experience: number;

  className?: string;
};

const ListItem = (props: ListItemProps) => {
  const navigate = useNavigate();

  return (
    <div
      className={cn(
        "flex flex-row gap-3 border rounded-lg border-gray-200 p-2 items-center w-72",
        props.className || ""
      )}
    >
      <Typography variant="p" affects="light" className="text-sm w-6">
        {props.position.toString().padStart(2, "0")}
      </Typography>

      <div className="flex flex-row gap-2 w-full items-center">
        <Avatar
          affects="noborder"
          size="xs"
          className="hover:cursor-pointer"
          onClick={() => navigate(`/profile/${props.userId}`)}
        >
          <AvatarImage src={props.avatarUrl} />
          <AvatarFallback>
            {getFallbackName(props.firstName, props.lastName)}
          </AvatarFallback>
        </Avatar>

        <div className="flex flex-row w-full min-w-44 items-center justify-between gap-2">
          <div>
            <Typography
              variant="p"
              affects="link"
              className="text-sm truncate max-w-32"
              onClick={() => navigate(`/profile/${props.userId}`)}
            >
              {getDisplayName(props.firstName, props.lastName)}
            </Typography>

            <Typography
              variant="p"
              affects="muted"
              className="text-xs mt-[-4px]"
            >
              {props.teamCodeName}
            </Typography>
          </div>

          <Typography variant="p" className="text-xs text-primary">
            {props.experience} XP
          </Typography>
        </div>
      </div>
    </div>
  );
};

type Tab = "COMPANY" | "TEAMS" | "FRIENDS";

export const Top5LastMonth = () => {
  const companyId = useAuthStore((store) => store.companyId);

  const [type, setType] = useState<Tab>(companyId ? "COMPANY" : "FRIENDS");
  const [leaderboard, setLeaderboard] = useState<LeaderboardUser[]>([]);

  useEffect(() => {
    const loadLeaderboard = async () => {
      const startOfPrevMonth = dayjs()
        .subtract(1, "month")
        .startOf("month")
        .startOf("day");

      const endOfPrevMonth = dayjs()
        .subtract(1, "month")
        .endOf("month")
        .endOf("day");

      const leaderboard = await getLeaderboard({
        type,
        timeStart: startOfPrevMonth.toDate(),
        timeEnd: endOfPrevMonth.toDate(),
      });

      setLeaderboard(leaderboard as LeaderboardUser[]);
    };

    loadLeaderboard();
  }, [type]);

  return (
    <div className="flex flex-col bg-white rounded-lg border border-slate-200 p-4 h-auto">
      <Typography variant="h3" className="text-center">
        Top performers
      </Typography>

      <Typography
        variant="p"
        affects="muted"
        className="text-sm text-center mt-[-4px] mb-1"
      >
        Last month
      </Typography>

      <div className="flex flex-col gap-2">
        <Tabs value={type} onValueChange={(value) => setType(value as Tab)}>
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="COMPANY" disabled={!companyId}>
              Company
            </TabsTrigger>
            <TabsTrigger value="FRIENDS">Friends</TabsTrigger>
          </TabsList>
        </Tabs>

        {leaderboard.length === 0 ? (
          <div className="flex items-center justify-center py-4">
            <Typography variant="p" affects="light" className="text-sm">
              No data available.
            </Typography>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            {leaderboard.slice(0, 3).map((user, index) => (
              <ListItem
                {...user}
                position={index + 1}
                key={user.userId}
                className={`w-full ${
                  index === 0
                    ? "border-orange-400"
                    : index === 1
                    ? "border-slate-300"
                    : "border-teal-400"
                }`}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
