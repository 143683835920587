import { client } from "../client";

export type CancelFriendRequestRequestDTO = {
  friendRequestId: string;
};

export const cancelFriendRequest = async (
  params: CancelFriendRequestRequestDTO
) => {
  await client.delete(`/friends/requests/${params.friendRequestId}`);
};
