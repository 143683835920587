import { useEffect, useState } from "react";
import { Friend, getFriends } from "../../../data/profile/get-friends";
import { removeFriend } from "../../../data/profile/remove-friend";
import { useAuthStore } from "../../../stores/auth.store";
import Typography from "../../typography";
import { Input } from "../input";
import { FriendCard } from "./friend-card";

type FriendListProps = {
  userId: string;
};

export const FriendList = (props: FriendListProps) => {
  const { userId } = props;

  const loggedInUserId = useAuthStore((store) => store.userId);

  const [nameFilter, setNameFilter] = useState<string>("");
  const [friends, setFriends] = useState<Friend[]>([]);

  useEffect(() => {
    const loadUserFriends = async () => {
      const data = await getFriends({
        userId,
      });

      setFriends(data);
    };

    loadUserFriends();
  }, [userId]);

  const onClickRemoveFriend = async (friendId: string) => {
    await removeFriend({
      userId: friendId,
    });

    const newFriends = friends.filter((f) => f.id !== friendId);
    setFriends(newFriends);
  };

  return (
    <div className="flex flex-col w-full gap-2 p-4 bg-white border border-gray-200 rounded-md">
      <div className="flex flex-col sm:flex-row justify-between w-full">
        <div className="flex flex-row items-end gap-3">
          <Typography variant="h3">Friends</Typography>
          <Typography variant="h3" affects="light" className="text-blue-500">
            {friends.length}
          </Typography>
        </div>

        <Input
          type="text"
          className="w-64"
          placeholder="Filter friends by name..."
          onChange={(value) => setNameFilter(value.currentTarget.value)}
        />
      </div>

      {friends
        .filter((friend) =>
          `${friend.firstName.toLowerCase()} ${friend.lastName.toLowerCase()}`.includes(
            nameFilter.toLowerCase()
          )
        )
        .sort((a, b) => a.firstName.localeCompare(b.firstName))
        .map((f) => (
          <FriendCard
            key={f.id}
            friend={f}
            onClickRemove={
              loggedInUserId === userId
                ? () => onClickRemoveFriend(f.id)
                : undefined
            }
          />
        ))}
    </div>
  );
};
