import { ColumnDef, Row } from "@tanstack/react-table";
import dayjs from "dayjs";
import { MoreHorizontal } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { deleteActivity } from "../../data/activities/delete-activity";
import { Activity } from "../../data/activities/get-home";
import { ProfileActivity } from "../../data/activities/get-user-activities";
import { formatDate, formatPace, formatTime } from "../../lib/date-utils";
import {
  getIconBySportType,
  getPaceFormatBySportType,
} from "../../lib/sport-type-utils";
import { useAuthStore } from "../../stores/auth.store";
import { ApplyXPBoost } from "../dialogs/apply-xp-boost-dialog";
import Typography from "../typography";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Button } from "../ui/button";
import { DataTable } from "../ui/data-table";
import { DataTableColumnFilter } from "../ui/data-table-column-filter";
import { DataTableColumnHeader } from "../ui/data-table-column-header";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

type ActivitiesTableProps = {
  activities: Array<Activity | ProfileActivity>;
  showUserColumn: boolean;
};

export const ActivitiesTable = (props: ActivitiesTableProps) => {
  const navigate = useNavigate();

  const userId = useAuthStore((store) => store.userId);
  const isOwnProfile =
    props.activities.length > 0 && userId === props.activities[0].author.id;

  const onClickDeleteActivity = async (activityId: string) => {
    await deleteActivity({
      activityId,
    });

    navigate(0);
  };

  const getColumns = (showUserColumn: boolean, showActionsColumn: boolean) => {
    const columns: ColumnDef<Activity | ProfileActivity>[] = [
      ...(showUserColumn
        ? [
            {
              header: "User",
              cell: ({ row }: { row: Row<Activity | ProfileActivity> }) => (
                <div className="flex flex-row h-full items-center gap-2">
                  <Avatar>
                    <AvatarImage src={row.original.author.avatarUrl} />
                    <AvatarFallback>
                      {row.original.author.firstName}
                    </AvatarFallback>
                  </Avatar>
                </div>
              ),
            },
          ]
        : []),
      {
        accessorKey: "type",
        header: ({ column }) => (
          <DataTableColumnFilter
            column={column}
            title="Sport"
            options={[
              { label: "Running", value: "running" },
              { label: "Biking", value: "biking" },
              { label: "Swimming", value: "swimming" },
              { label: "Walking", value: "walking" },
            ]}
          />
        ),
        cell: ({ row }) => (
          <Typography variant="p">
            {getIconBySportType(row.getValue("type"))}
          </Typography>
        ),
        filterFn: (row, id, value: string[]) => {
          return value.length === 0 || value.includes(row.getValue(id));
        },
      },
      {
        accessorKey: "createdAt",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Date" />
        ),
        cell: ({ row }) => (
          <div className="flex flex-col">
            <Typography variant="p">
              {formatDate(row.original.createdAt, "DD/MM/YYYY")}
            </Typography>
            <Typography variant="p" affects="muted" className="text-xs">
              {formatDate(row.original.createdAt, "HH:mm")}
            </Typography>
          </div>
        ),
      },
      {
        accessorKey: "distance",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Distance" />
        ),
        cell: ({ row }) => (
          <div className="flex flex-col">
            <Typography variant="p">{row.getValue("distance")}</Typography>
            <Typography variant="p" affects="muted" className="text-xs">
              {"KM"}
            </Typography>
          </div>
        ),
      },
      {
        accessorKey: "duration",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Time" />
        ),
        cell: ({ row }) => (
          <div className="flex flex-col">
            <Typography variant="p">
              {formatTime(row.getValue("duration"), "HH:mm:ss")}
            </Typography>
            <Typography variant="p" affects="muted" className="text-xs">
              {"HH:MM:SS"}
            </Typography>
          </div>
        ),
      },
      {
        accessorKey: "pace",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Pace" />
        ),
        cell: ({ row }) => (
          <div className="flex flex-col">
            <Typography variant="p">
              {formatPace(
                row.getValue("duration"),
                row.getValue("distance"),
                row.original.type
              )}
            </Typography>
            <Typography variant="p" affects="muted" className="text-xs">
              {getPaceFormatBySportType(row.original.type)}
            </Typography>
          </div>
        ),
      },
      {
        accessorKey: "elevation",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Elev" />
        ),
        cell: ({ row }) => (
          <div className="flex flex-col">
            <Typography variant="p">{row.getValue("elevation")}</Typography>
            <Typography variant="p" affects="muted" className="text-xs">
              {"M"}
            </Typography>
          </div>
        ),
      },
      {
        accessorKey: "experience",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="EXP" />
        ),
        cell: ({ row }) => (
          <div className="flex flex-col">
            <Typography variant="p">{row.getValue("experience")}</Typography>
            <Typography variant="p" affects="muted" className="text-xs">
              EXP
            </Typography>
          </div>
        ),
      },
      {
        accessorKey: "bonusExp",
        header: ({ column }) => (
          <DataTableColumnHeader column={column} title="Bonus EXP" />
        ),
        cell: ({ row }: { row: Row<Activity | ProfileActivity> }) => (
          <div className="flex items-baseline">
            {row.original.author.id === userId &&
            !row.original.xpBoostId &&
            dayjs(row.original.createdAt).add(1, "day").toDate() >=
              new Date() ? (
              <ApplyXPBoost
                activityId={row.original.id}
                activityExp={row.original.experience}
                activityCreatedAt={row.original.createdAt}
              />
            ) : (
              <div className="flex flex-col">
                <Typography variant="p">{row.original.bonusExp}</Typography>
                <Typography variant="p" affects="muted" className="text-xs">
                  EXP{" "}
                  {row.original.bonusExp > 0 &&
                    `(${Math.round(
                      (row.original.bonusExp / row.original.experience) * 100
                    )}%)`}
                </Typography>
              </div>
            )}
          </div>
        ),
      },
      ...(showActionsColumn
        ? [
            {
              id: "actions",
              cell: ({ row }: { row: Row<Activity | ProfileActivity> }) => (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" className="h-8 w-8 p-0">
                      <span className="sr-only">Open menu</span>
                      <MoreHorizontal className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>

                  <DropdownMenuContent align="end">
                    <DropdownMenuLabel>Actions</DropdownMenuLabel>

                    <Dialog>
                      <DialogTrigger asChild>
                        <DropdownMenuItem
                          onSelect={(e) => {
                            // Prevent dropdown menu from closing the dialog
                            e.preventDefault();
                          }}
                        >
                          <Typography
                            variant="p"
                            className="text-xs hover:text-red-400 hover:cursor-pointer"
                          >
                            Delete activity
                          </Typography>
                        </DropdownMenuItem>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogHeader>
                          <DialogTitle>Deleting activity</DialogTitle>
                          <DialogDescription>
                            Are you sure you want delete this activity forever?
                            <br />
                            Any experience gained from it will be removed.
                          </DialogDescription>

                          <DialogFooter>
                            <DialogClose
                              className="flex flex-row justify-end gap-4"
                              asChild
                            >
                              <Button variant="secondary">Cancel</Button>
                            </DialogClose>

                            <Button
                              variant="destructive"
                              onClick={() =>
                                onClickDeleteActivity(row.original.id)
                              }
                            >
                              Delete
                            </Button>
                          </DialogFooter>
                        </DialogHeader>
                      </DialogContent>
                    </Dialog>
                  </DropdownMenuContent>
                </DropdownMenu>
              ),
            },
          ]
        : []),
    ];

    return columns;
  };

  return (
    <div className="flex flex-col gap-2 bg-white rounded-lg border border-slate-200 p-4">
      <div className="flex flex-row items-end gap-3">
        <Typography variant="h3">Activities</Typography>
        <Typography variant="h3" affects="light" className="text-blue-500">
          {props.activities.length}
        </Typography>
      </div>

      <DataTable
        columns={getColumns(props.showUserColumn, isOwnProfile)}
        data={props.activities}
      />
    </div>
  );
};
