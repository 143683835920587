import { useNavigate } from "react-router-dom";
import { Grid } from "../../../data/minigames/grid-reveal/get-grid-by-id";
import { getFallbackName } from "../../../lib/name-utils";
import { useAuthStore } from "../../../stores/auth.store";
import Typography from "../../typography";
import { Avatar, AvatarFallback, AvatarImage } from "../avatar";
import { Badge } from "../badge";

type GridComponentProps = {
  grid: Grid;
  onCellClick: (x: number, y: number) => void;
  onPreviousLevel: () => void;
  onNextLevel: () => void;
  highlightedCell: { x: number; y: number } | null;
};

export const GameGrid = ({
  grid,
  onCellClick,
  onPreviousLevel,
  onNextLevel,
  highlightedCell,
}: GridComponentProps) => {
  const navigate = useNavigate();

  // Create a 2D array representation of the grid
  const gridArray = Array(8)
    .fill(null)
    .map(() => Array(5).fill(null));
  grid.cells.forEach((cell) => {
    gridArray[cell.y][cell.x] = cell;
  });

  const userId = useAuthStore((store) => store.userId);
  const isMyGrid = grid.user.id === userId;

  return (
    <div className="w-full h-full bg-white p-2 flex flex-col">
      <div className="flex justify-between px-1">
        <Typography variant="h3" className="text-center">
          Grid Reveal
        </Typography>

        <Typography variant="p" affects="removePMargin" className="text-center">
          Level {grid.level} {grid.isFinished ? "✅" : ""}
        </Typography>
      </div>

      <div className="flex md:hidden items-center gap-2 px-2 mt-2">
        <Avatar
          affects="noborder"
          className="hover:cursor-pointer"
          onClick={() => navigate(`/profile/${userId}`)}
        >
          <AvatarImage src={grid.user.avatarUrl} alt="Avatar" />
          <AvatarFallback>
            {getFallbackName(grid.user.firstName, grid.user.lastName)}
          </AvatarFallback>
        </Avatar>

        <div>
          <Typography
            variant="p"
            affects="link"
            onClick={() => navigate(`/profile/${userId}`)}
          >
            {grid.user.firstName} {grid.user.lastName}
          </Typography>
          <Badge
            variant="secondary"
            className="bg-blue-400 bg-opacity-10 text-md"
          >
            <Typography variant="p" affects="muted" className="text-blue-400">
              {grid.user.energy} left ⚡
            </Typography>
          </Badge>
        </div>
      </div>

      <div className="overflow-auto p-1 mt-4">
        <div className="grid grid-cols-5 grid-rows-8 gap-1 min-h-min">
          {gridArray.map((row, rowIndex) =>
            row.map((cell, colIndex) => {
              if (!cell)
                return (
                  <div
                    key={`${rowIndex}-${colIndex}`}
                    className="aspect-square"
                  />
                );

              const cellBackgroundColor = cell.claimedAt
                ? "bg-gray-300"
                : "bg-white";

              const cellBorderColor =
                cell.difficulty === "easy"
                  ? "border-blue-200"
                  : cell.difficulty === "medium"
                  ? "border-purple-200"
                  : "border-red-200";

              const isHighlighted =
                highlightedCell &&
                highlightedCell.x === cell.x &&
                highlightedCell.y === cell.y;

              return (
                <div
                  key={`${rowIndex}-${colIndex}`}
                  className={`
                      w-14 h-10 md:h-auto md:w-auto md:aspect-square rounded-md border flex flex-col items-center justify-center text-xs md:text-sm font-semibold
                      ${cell.claimedAt || !isMyGrid ? "" : "cursor-pointer"}
                      ${cellBackgroundColor}
                      ${cellBorderColor}
                      ${isHighlighted ? "ring-2 ring-blue-500" : ""}
                    `}
                  onClick={() =>
                    !cell.claimedAt && isMyGrid && onCellClick(cell.x, cell.y)
                  }
                >
                  <span>
                    {Math.round(
                      cell.energyRequirement * (1 - cell.discount / 100)
                    )}
                  </span>
                  {!cell.claimedAt && cell.discount > 0 && (
                    <span className="text-xs text-green-600">
                      -{cell.discount}%
                    </span>
                  )}
                </div>
              );
            })
          )}
        </div>
      </div>

      <div className="px-1 pt-2 flex justify-between">
        <button
          onClick={onPreviousLevel}
          disabled={!grid.previousGridId}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
        >
          Prev Level
        </button>
        <button
          onClick={onNextLevel}
          disabled={!grid.nextGridId && !grid.isFinished}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
        >
          Next Level
        </button>
      </div>
    </div>
  );
};
