import dayjs from "dayjs";

type WeekRectangleCardProps = {
  weekNumber: number;
  weekEndDate: string;
  objective: number;
  isSelected?: boolean;
  isCurrent?: boolean;
  onClick: () => void;
};

export function WeekRectangleCard({
  weekNumber,
  weekEndDate,
  objective,
  isSelected = false,
  isCurrent = false,
  onClick,
}: WeekRectangleCardProps) {
  const today = new Date();
  const itemDate = dayjs(weekEndDate);
  const isPastDate = itemDate.isBefore(today);

  return (
    <div className="w-24 snap-center" onClick={onClick}>
      <div
        className={`relative aspect-square bg-white border rounded-md overflow-hidden transition-all duration-300 ease-in-out cursor-pointer hover:shadow-lg 
          ${isPastDate ? "opacity-50" : ""} 
          ${isSelected ? "border-blue-400" : "border-slate-200"}
        `}
      >
        <div className="absolute inset-0.5 bg-white rounded-md flex flex-col justify-center items-center p-4">
          <span
            className={`text-xs font-medium uppercase mb-1 mt-2 ${
              isCurrent ? "text-blue-400" : "text-gray-400"
            }`}
          >
            {isCurrent ? "CURRENT" : "WEEK"}
          </span>

          <span
            className={`text-4xl font-bold ${
              isSelected ? "text-blue-400" : "text-gray-800"
            }`}
          >
            {weekNumber}
          </span>

          <div className="flex items-baseline">
            <span className="text-lg text-gray-600 mr-1">{objective}</span>
            <span className="text-xs text-gray-400 uppercase">km</span>
          </div>
        </div>
      </div>
    </div>
  );
}
